<template>
  <div class="recycle-index">
    <RecycleBinTable></RecycleBinTable>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, onMounted } from "vue";
import { httpPost } from "@/api/httpService.js";
import RecycleBinTable from "./components/RecycleBinTable.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);

const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "回收站",
      name: "RecycleBin",
      params: {},
    },
  ];
  const pageTitle = "回收站";
  emits("setBreadcrumbList", breadcrumbList);
  emits("setPageTitle", pageTitle);
};

onMounted(async () => {
  await setBreadcrumb();
});
</script>

<style lang="scss" scoped></style>
