<template>
  <el-radio-group
    v-bind="$attrs"
    :modelValue="modelValue"
    @update:modelValue="handleValueChange"
    class="radio-group"
  >
    <slot></slot>
  </el-radio-group>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits } from "vue";
import { httpPost } from "@/api/httpService.js";

const props = defineProps({
  modelValue: {},
});
const emits = defineEmits(["update:modelValue"]);

const handleValueChange = (value) => {
  emits("update:modelValue", value);
};
</script>

<style lang="scss" scoped>
.radio-group {
  :deep(.el-radio-button__inner) {
    border: 0px;
    color: #6c6c6c;
    background: none;
    padding: 7px 15px;
    font-size: 14px;
  }
  :deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
    background: #e2e1e1;
    border-radius: 4px;
    box-shadow: none;
  }
}
</style>
