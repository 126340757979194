<template>
  <div
    class="list-table"
    :style="[data?.length == 0 ? 'height:calc(100% - 40px)' : '']"
  >
    <div class="table-wrapper" v-show="data?.length > 0">
      <el-table
        :data="data"
        v-loading="loading"
        header-cell-class-name="list-table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="45px" />
        <el-table-column prop="name" label="策略名称" show-overflow-tooltip>
          <template #default="scope">
            <div class="schedule-name" @click="handleView(scope.row)">
              <i class="iconfont icon-jihuarenwu" style="margin-right: 8px"></i>
              <div class="name">
                {{ scope.row.name }}
              </div>
              <ScheduleStatusTag
                class="status-tag"
                :status="scope.row.status"
                style="margin-left: 8px"
              ></ScheduleStatusTag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="associatedProjectNum"
          label="关联项目数"
          width="120px"
        />
        <el-table-column prop="type" label="策略类型" width="80px">
          <template #default="scope">
            <div>
              {{ SCHEDULE_TYPE_MAP[scope.row.type].label }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="executionType" label="执行类型" width="80px">
          <template #default="scope">
            <div>
              {{ EXECUTION_TYPE_MAP[scope.row.executionType].label }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="最近一次执行时间" width="140">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.lastExecutionTime) }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="status" label="策略状态">
          <template #default="scope">
            <ScheduleStatusTag :status="scope.row.status"></ScheduleStatusTag>
          </template>
        </el-table-column> -->
        <el-table-column prop="updateTime" label="更新时间" width="110px">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.updateTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170px" fixed="right">
          <template #default="scope">
            <div class="list-item-btn">
              <div>
                <el-button
                  link
                  @click="handleClickEnable(scope.row)"
                  v-if="ifShowEffectButton(scope.row)"
                  :disabled="scope.row.ifOptionDisabled"
                >
                  生效
                </el-button>
                <el-button
                  link
                  v-if="
                    scope.row.executionType == EXECUTION_TYPE.REPEATED &&
                    scope.row.status === SCHEDULE_STATUS.ENABLED
                  "
                  :disabled="scope.row.ifOptionDisabled"
                  @click="handleClickPause(scope.row)"
                >
                  暂停
                </el-button>
                <el-button
                  link
                  @click="handleClickEnable(scope.row)"
                  v-if="ifShowReplayButton(scope.row)"
                  :disabled="scope.row.ifOptionDisabled"
                >
                  重新执行
                </el-button>
                <el-button
                  link
                  :disabled="scope.row.ifOptionDisabled"
                  @click="handleClickEdit(scope.row)"
                >
                  编辑
                </el-button>
                <el-button
                  link
                  :disabled="scope.row.ifOptionDisabled"
                  @click="handleClickDelete(scope.row)"
                >
                  删除
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="empty-container">
      <EmptyStatus
        v-show="!ifFirst && data.length == 0"
        :imageSrc="require('@/assets/imgs/empty2.png')"
        :size="300"
      >
        <template #description>
          <slot name="empty">
            <div style="font-size: 18px; margin-bottom: 12px">
              暂无策略，立即
              <span class="description-button" @click="handleClickAddSchedule">
                新建策略
              </span>
            </div>
            <div style="font-size: 16px">
              策略用来设置采集项目的执行时间，比如可以设置项目定时采集、循环采集等。
            </div>
          </slot>
        </template>
      </EmptyStatus>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  getCurrentInstance,
  watch,
  onMounted,
  nextTick,
  defineExpose,
} from "vue";
import { httpPost, baseUrl } from "@/api/httpService.js";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { Debounce } from "@/utils/utils.js";
import { timeFormatter } from "@/utils/time.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import {
  SCHEDULE_STATUS_MAP,
  SCHEDULE_STATUS,
} from "@/constant/schedule_status.js";
import {
  SCHEDULE_TYPE_MAP,
  EXECUTION_TYPE_MAP,
  EXECUTION_TYPE,
} from "@/constant/schedule.js";
import { remindErrorMessage } from "@/utils/utils";
import ScheduleStatusTag from "@/components/base/ScheduleStatusTag.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";

const router = useRouter();
const route = useRoute();

const props = defineProps({
  data: {
    type: Array,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["edit", "delete", "enable", "pause", "view", "add"]);

watch(
  () => props.loading,
  (newVal) => {
    console.log("watch iffirst", newVal);
    if (newVal == false) {
      ifFirst.value = false;
    }
  }
);
const ifFirst = ref(true);

// #region 表格数据相关
const keywords = ref("");
const scheduleList = ref([]);

// #endregion 表格数据相关

// #region 表格批量操作相关
// 记录列表勾选后被选中的id
const selectedItemList = ref([]);
const handleSelectionChange = (val) => {
  console.log("🚀 ~ handleSelectionChange ~ val:", val);

  selectedItemList.value = val;
};

const ifShowEffectButton = (cardData) => {
  // 策略为未生效状态或策略为重复执行且策略为暂停中显示
  return (
    cardData.status == SCHEDULE_STATUS.DISABLED ||
    (cardData.status == SCHEDULE_STATUS.PAUSE &&
      cardData.executionType == EXECUTION_TYPE.REPEATED)
  );
};

const ifShowReplayButton = (cardData) => {
  // 策略为立即执行且策略为已结束时显示
  return (
    cardData.status == SCHEDULE_STATUS.COMPLETED &&
    cardData.executionType == EXECUTION_TYPE.IMMEDIATE
  );
};

const handleClickDelete = (schedule) => {
  schedule.ifOptionDisabled = true;

  emits("delete", schedule);
};
const handleClickEdit = (schedule) => {
  emits("edit", schedule);
};
const handleClickEnable = (schedule) => {
  schedule.ifOptionDisabled = true;

  emits("enable", schedule);
};
const handleClickPause = (schedule) => {
  schedule.ifOptionDisabled = true;

  emits("pause", schedule);
};

const handleView = (schedule) => {
  emits("view", schedule);
};
const handleClickAddSchedule = () => {
  emits("add");
};
onMounted(async () => {
  // await getScheduleList();
});

defineExpose({
  selectedItemList,
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
.table-wrapper {
  padding: 20px;
  background: #fff;
  .schedule-name {
    cursor: pointer;
    display: flex;
    .icon-jihuarenwu {
      color: $theme-color;
      flex-shrink: 0;
    }
    .name {
      flex-shrink: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .status-tag {
      width: 48px;
      flex-shrink: 0;
    }
  }
}
.empty-container {
  height: 100%;
}
</style>
