<template>
  <el-check-tag
    class="tag-check"
    :style="
      dropable
        ? onEditing
          ? 'padding: 0px'
          : 'padding: 7px 10px 7px 15px;'
        : ''
    "
    :checked="checked"
    :key="key"
    @click="handleClickTag"
    :disable-transitions="true"
  >
    <div class="tag-edit" v-if="onEditing">
      <el-input
        ref="inputEditRef"
        class="tag-input"
        v-model="inputEditValue"
        :placeholder="label"
        @keyup.enter="handleEditTag"
      />
    </div>
    <div class="tag-item" v-else>
      <div class="tag-name" v-showTip>
        <el-tooltip
          :content="label + labelSuffix"
          placement="top"
          effect="light"
        >
          <div class="tag-text">
            <span class="ellipse-text">{{ label + labelSuffix }}</span>
          </div>
        </el-tooltip>
      </div>
      <div class="tag-dropdown" v-if="dropable">
        <!-- '全部'标签不显示图标 -->
        <el-dropdown>
          <span>
            <el-icon class="el-icon--right">
              <i class="iconfont icon-gengduo" />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <slot name="dropdown-menu" />
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </el-check-tag>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  defineExpose,
  nextTick,
  computed,
} from "vue";

const emits = defineEmits(["tag-click", "tag-edit", "inputRef"]);

const props = defineProps({
  key: {
    type: Number,
    default: () => 0,
  },
  label: {
    type: String,
    default: () => "",
  },
  labelSuffix: {
    type: String,
    default: () => "",
  },
  onEditing: {
    type: Boolean,
    default: () => false,
  },
  dropable: {
    type: Boolean,
    default: () => false,
  },
  checked: {
    type: Boolean,
    default: () => false,
  },
});

const inputEditValue = ref("");
const inputEditRef = ref(null);
const handleClickTag = () => {
  emits("tag-click");
};
const handleEditTag = () => {
  emits("tag-edit", inputEditValue.value, props.label);
};
const focusInput = () => {
  nextTick(() => {
    inputEditRef.value.focus();
  });
};

watch(
  () => props.label,
  (newVal) => {
    inputEditValue.value = newVal;
  },
  {
    immediate: true,
  }
);

defineExpose({ focusInput });
</script>

<style lang="scss" scoped>
.tag-check {
  transition: none;
  margin-bottom: 10px;
  margin-right: 15px;
  line-height: 19px;
  background-color: #f5f5f5;
  :deep(.el-button) {
    border: 0px;
    background-color: #f5f7fa;
  }
  .tag-edit {
    .tag-input {
      height: 33px;
      width: 119px;
    }
  }
  .tag-item {
    display: flex;
    .tag-name {
      min-width: 50px;
      max-width: 70px;
      text-align: center;
      color: #6c6c6c;
      font-weight: 500;
      .tag-text {
        @include hidden-with-ellipsis();
        .ellipse-text {
        }
      }
    }
    .tag-name:hover {
      color: $theme-color;
    }
    .tag-dropdown {
      display: flex;
      align-items: center;
      margin-left: 5px;
      .iconfont {
        font-size: 12px;
        color: #6c6c6c;
      }
    }
  }
}
.tag-check:hover {
  background-color: #f7f4fe;
  color: $theme-color;
}
.is-checked.tag-check {
  background-color: #f7f4fe;
  color: $theme-color;
  .tag-name {
    color: $theme-color;
  }
}
</style>
