<template>
  <div class="comment-container">
    <div v-if="total">
      <div class="comment-content">
        <div class="comment-left">
          <div class="avatar">
            <span>
              <i
                class="iconfont icon-touxiang"
                style="color: #9a9a9a; font-size: 20px"
              ></i>
            </span>
            <span class="username">{{ data.userName }}</span>
          </div>
          <div class="comment-info">
            <div class="rate">
              <el-rate
                v-model="starNum"
                show-score
                disabled
                :colors="['#f85926', '#f85926', '#f85926']"
                text-color="#ff9900"
                score-template="{value} 分"
              ></el-rate>
            </div>
            <div class="comment">{{ data.content }}</div>
            <div class="time">{{ timeFormatter(data.commentTime) }}</div>
          </div>
        </div>
        <div class="comment-right">
          <span
            class="delete-icon"
            @click="handleDeleteComment"
            v-if="data.isOwner === 1"
          >
            <i class="iconfont icon-shanchu1"></i>
          </span>
          <div class="icon-like-reply">
            <span class="dianzan-icon">
              <i class="iconfont icon-dianzan"></i>
              <!-- <span>2</span> -->
            </span>
            <span class="pinglun-icon">
              <i
                class="iconfont icon-pinglun"
                @click="openReplyInput(data)"
              ></i>
            </span>
            <span class="sub-comment-count">{{ data.commentList.length }}</span>
          </div>
        </div>
      </div>
      <SpiderSubCommentCard
        :subCommentList="data.commentList"
        @openReplyInput="openReplyInput"
        @deleteComment="onDeleteComment"
      />
      <div class="reply-input" v-if="ifShowReplyInput">
        <div class="replyer-info">
          <span class="icon">
            <i
              class="iconfont icon-touxiang"
              style="color: #9a9a9a; font-size: 20px"
            ></i>
          </span>
          <span class="title">{{ userInfo.name }}</span>
        </div>
        <div class="input">
          <el-input
            v-model="replyContent"
            :placeholder="replyPlaceholder"
            type="textarea"
            :rows="inputRow"
            ref="replyInputRef"
            @mouseenter="adjustInputHeight"
          />
        </div>
        <el-button type="primary" @click="onReplyComment">回复</el-button>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script setup>
import EmptyStatus from "@/components/base/EmptyStatus.vue";
import { ref, nextTick, defineEmits, defineProps, watch } from "vue";
import { ElMessage } from "element-plus";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { timeFormatter } from "@/utils/time.js";
import { useRoute } from "vue-router";
import { httpPost } from "@/api/httpService.js";
import SpiderSubCommentCard from "./SpiderSubCommentCard.vue";
import { useUserInfo } from "@/utils/useUserInfo.js";
const route = useRoute();
const spiderInfoUid = ref("");
const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  total: {
    type: Number,
  },
});
watch(
  () => route.params,
  (newVal) => {
    spiderInfoUid.value = newVal.spiderUid;
  },
  {
    immediate: true,
    deep: true,
  }
);
const userInfo = useUserInfo();

const starNum = ref(props.data.score);

const replyContent = ref("");

const ifShowReplyInput = ref(false);

const replyInputRef = ref(null);

const fatherUid = ref("");

const isMain = ref(null);

const replyPlaceholder = ref("回复: 我是用户名");

const inputRow = ref(1);

const emits = defineEmits(["refresh"]);

const currentCommentUid = ref(null);

const textColor = "#ff9900";

const openReplyInput = (comment) => {
  replyPlaceholder.value = "回复" + " " + comment.userName;
  if (ifShowReplyInput.value && currentCommentUid.value === comment.uid) {
    // 如果输入框已经显示且点击的评论具有相同的 uid，则隐藏它
    ifShowReplyInput.value = false;
  } else {
    // 如果输入框尚未显示或点击的评论具有不同的 uid，则显示它
    ifShowReplyInput.value = true;
    currentCommentUid.value = comment.uid;
    nextTick(() => {
      replyInputRef.value.focus();
    });
  }
};
const onDeleteComment = async (commentUid) => {
  let params = {
    commentUid,
  };
  let res = await httpPost("/spiderMarket/v1/deleteComment", params);
  if (res.code == 0) {
    ElMessage.success("删除成功");
    emits("refresh");
  }
};
//TODO 删除用户自己发布的评论-待对接
const handleDeleteComment = async () => {
  confirmDeleteBox("是否确定删除该评论")
    .then(() => {
      onDeleteComment(props.data.uid);
    })
    .catch(() => {});
};
const adjustInputHeight = () => {
  inputRow.value = 4;
};
// //TODO 回复用户评论-待对接
const onReplyComment = async () => {
  fatherUid.value = currentCommentUid.value;
  if (fatherUid.value !== "") {
    isMain.value = 0;
  } else {
    isMain.value = 1;
  }
  let params = {
    marketSpiderUid: spiderInfoUid.value,
    isMain: isMain.value,
    fatherUid: fatherUid.value,
    content: replyContent.value,
  };
  let res = await httpPost("/spiderMarket/v1/addComment", params);
  if (res.code == 0) {
    ifShowReplyInput.value = false;
    ElMessage.success("回复成功");
    replyContent.value = "";
    emits("refresh");
  }
};
// //TODO-点赞评论-待对接
// const onLikeComment = () => {
//   let params = {};
// };
</script>

<style lang="scss" scoped>
.comment-container {
  margin-top: 20px;
  .comment-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .comment-left {
      display: flex;
      align-items: first baseline;
      gap: 40px;
      margin-left: 20px;
      .avatar {
        display: flex;
        align-items: center;
        gap: 3px;
        .username {
          width: 76px;
          color: #9a9a9a;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .comment-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        .time {
          color: #9a9a9a;
        }
      }
    }
    .comment-right {
      margin-right: 20px;
      .delete-icon {
        cursor: pointer;
      }
      .icon-like-reply {
        position: relative;
        top: 65px;
        right: 30px;
        .dianzan-icon {
          cursor: pointer;
          margin-right: 20px;
        }
        .pinglun-icon {
          cursor: pointer;
        }
        .sub-comment-count {
          color: #9a9a9a;
          margin-left: 3px;
        }
      }
    }
  }
  .reply-input {
    margin-left: 130px;
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    .replyer-info {
      display: flex;
      align-items: center;
      gap: 5px;
      .title {
        color: #9a9a9a;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .input {
      width: 100%;
    }
  }
  .line {
    margin-top: 20px;
    width: 100%;
    height: 1px;
    background-color: #e7e7e7;
  }
}
</style>
