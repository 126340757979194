<template>
  <div class="overview-card" v-loading="loading">
    <div class="header">
      <div class="title">最近任务</div>
      <div class="option" v-if="tableData.length > 0" @click="goTask">更多</div>
    </div>
    <div class="main" v-if="tableData.length > 0">
      <div class="main-table">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="taskNumber"
            label="任务ID"
            width="80"
            show-overflow-tooltip
          >
            <template #default="scope">
              <span class="column-id" @click="gotoDataPage(scope.row)">
                {{ scope.row.taskNumber }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="项目名称">
            <template #default="scope">
              <div class="colunm-name">
                <el-tooltip placement="bottom-start" effect="light">
                  <template #content>
                    {{ scope.row.projectName }}
                  </template>
                  {{ scope.row.projectName }}
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="190">
            <template #default="scope">
              {{ timeFormatterDetail(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="collectNum" label="采集数量" width="80" />
          <el-table-column prop="status" label="状态" width="90">
            <template #default="scope">
              <el-tooltip
                effect="light"
                placement="bottom"
                content="当前项目有任务排队中/进行中，策略生成任务失败"
                v-if="scope.row.status === TASK_STATUS_TYPE.FAILED"
              >
                <TaskStatus :status="scope.row.status"></TaskStatus>
              </el-tooltip>
              <TaskStatus :status="scope.row.status" v-else></TaskStatus>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="empty" v-if="!loading && tableData.length === 0">
      <EmptyStatus :imageSrc="require('@/assets/imgs/empty1.png')">
        <template #description>
          <div class="empty-text">暂无内容</div>
        </template>
      </EmptyStatus>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, defineProps, defineEmits } from "vue";
import { httpPost } from "@/api/httpService.js";
import TaskStatus from "@/components/base/TaskStatus.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
import { useRouter } from "vue-router";
import {
  TASK_STATUS_OPTION,
  TASK_STATUS_TYPE,
} from "@/constant/task_status.js";

import { timeFormatterDetail } from "@/utils/time.js";

const router = useRouter();

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const tableData = ref([]);
watch(
  () => props.data,
  (newVal, oldVal) => {
    tableData.value = newVal;
  },
  { immediate: true, deep: true }
);
const goTask = () => {
  router.push({
    name: "TaskRecordIndex",
  });
};
const gotoDataPage = (row) => {
  router.push({
    name: "ProjectData",
    // 重新对接-已完成
    params: {
      projectId: row.projectUid,
    },
    query: {
      taskId: row.taskUid,
    },
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/overview-card.scss";
.column-id {
  color: $theme-color;
  cursor: pointer;
}
.main-table {
  height: 250px;
  overflow: hidden;
  .colunm-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
