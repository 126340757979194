<template>
  <div class="page-option-bar">
    <span>
      <el-dropdown @command="handleBatchCommand">
        <el-button>
          批量操作
          <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="batchDelete">批量删除</el-dropdown-item>
            <el-dropdown-item command="batchAddTag">
              批量添加标签
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </span>
    <!-- <span>
      <el-button
        @click="handleClickCrawling"
        type="primary"
        class="button-plain"
      >
        立即采集
      </el-button>
    </span> -->
    <span>
      <el-button type="primary" @click="handleClickAddProject">
        新建采集项目
      </el-button>
    </span>
  </div>
  <div class="list-table">
    <div class="tag-bar">
      <el-skeleton class="skeleton-bar" animated v-if="tagListLoading">
        <template #template>
          <el-skeleton-item class="skeleton-item" v-for="i in 4" :key="i" />
        </template>
      </el-skeleton>
      <TagList
        ref="TagListRef"
        v-else
        @tag-add="handleClickAddTag"
        :addLabel="'新增标签'"
        addable
      >
        <TagListItem
          label="全部"
          :dropable="false"
          :checked="isAllChecked"
          @tag-click="handleClickAllTag"
        ></TagListItem>
        <TagListItem
          v-for="(item, index) in tagList"
          ref="tagItemRef"
          :key="index"
          :label="item.name"
          :labelSuffix="' (' + item.number + ')'"
          :checked="item.isChecked"
          :onEditing="item.onEditing"
          @tag-edit="
            (newVal, oldVal) => handleClickSaveTag(item, newVal, oldVal)
          "
          @tag-click="handleClickTag(item)"
          :dropable="item.createUser !== 0"
        >
          <template #dropdown-menu>
            <el-dropdown-item @click="handleClickDeleteTag(item)">
              删除
            </el-dropdown-item>
            <el-dropdown-item @click="handleClickEditTag(item, index)">
              重命名
            </el-dropdown-item>
          </template>
        </TagListItem>
      </TagList>
    </div>
    <div class="option-bar">
      <div class="option">
        <el-input
          v-model="keywords"
          placeholder="请输入项目名称进行检索"
          :prefix-icon="Search"
          @input="getProjectList"
          style="width: 400px"
          clearable
        />
        <div>
          <el-select v-model="projectStatus" placeholder="请选择状态">
            <el-option
              v-for="item in projectStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-button type="primary" @click="getProjectList">查询</el-button>
        </div>
      </div>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="projectList"
        v-loading="tableLoading"
        header-cell-class-name="list-table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          :selectable="ifCheckboxSelectable"
          type="selection"
          width="45px"
        />
        <el-table-column prop="name" label="项目名称" min-width="250px">
          <template #default="scope">
            <TextEditor
              icon="icon-wodexiangmu"
              :icon-style="{ color: '#7F44F0' }"
              :text="scope.row.name"
              editable
              v-model:edit="scope.row.onEditing"
              v-model:loading="scope.row.ifShowLoading"
              @save="(text) => handleClickSaveName(text, scope.row)"
              @go="handleClickName(scope.row)"
            ></TextEditor>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="项目状态" width="100">
          <template #default="scope">
            <ProjectStatusTag :status="scope.row.status" />
          </template>
        </el-table-column>
        <el-table-column
          prop="strategiesNum"
          label="有效策略数量"
          width="120"
          v-if="ifShowSchedulePermission"
        >
          <template #default="scope">
            <span
              style="color: #7f44f0; cursor: pointer"
              @click="goSchedule(scope.row)"
            >
              {{ scope.row.strategiesNum ?? 0 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="collectNum" label="采集数量">
          <template #default="scope">
            <span>{{ scope.row.collectNum ?? 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="140">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.createTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" width="140">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.updateTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170px" fixed="right">
          <template #default="scope">
            <div class="list-item-btn" v-if="!scope.row.onEditing">
              <div>
                <el-button link @click="goOverview(scope.row)" type="primary">
                  概览
                </el-button>
                <el-button link @click="goSpider(scope.row)" type="primary">
                  爬虫
                </el-button>
                <el-button link type="primary">
                  <el-dropdown
                    trigger="click"
                    @command="(command) => handleCommand(scope, command)"
                  >
                    <el-button link type="primary">
                      更多
                      <el-icon class="el-icon--right"><arrow-down /></el-icon>
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="goTask">
                          任务
                        </el-dropdown-item>
                        <el-dropdown-item command="goData">
                          数据
                        </el-dropdown-item>
                        <el-dropdown-item command="goLog">
                          日志
                        </el-dropdown-item>
                        <el-dropdown-item
                          @click="
                            handleClickSetProjectStatus(
                              scope.row,
                              PROJECT_STATUS.ENABLE
                            )
                          "
                          v-if="
                            scope.row.status === PROJECT_STATUS.READY ||
                            scope.row.status === PROJECT_STATUS.PAUSE
                          "
                        >
                          启用
                        </el-dropdown-item>
                        <el-dropdown-item
                          @click="
                            handleClickSetProjectStatus(
                              scope.row,
                              PROJECT_STATUS.PAUSE
                            )
                          "
                          v-if="PROJECT_STATUS.ENABLE"
                        >
                          暂停
                        </el-dropdown-item>
                        <!-- <el-dropdown-item command="goSchedule">
                          策略
                        </el-dropdown-item> -->
                        <el-dropdown-item
                          command="rename"
                          v-if="scope.row.createUser != 0"
                        >
                          重命名
                        </el-dropdown-item>
                        <el-dropdown-item
                          command="delete"
                          v-if="scope.row.createUser != 0"
                        >
                          删除
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          small
          background
          layout="total, prev, pager, next, sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        ></el-pagination>
      </div>
    </div>
    <ProjectDialogManage
      ref="projectDialogManageRef"
      @submit="initPage"
      width="40%"
    />
    <ProjectDialogAddTag
      ref="projectDialogAddTagRef"
      @submit="initPage"
      width="45%"
    />
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  getCurrentInstance,
  watch,
  onMounted,
  nextTick,
} from "vue";
import { Search } from "@element-plus/icons-vue";
import { httpPost, baseUrl } from "@/api/httpService.js";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { usePagination } from "@/utils/usePagination";
import { Debounce } from "@/utils/utils.js";
import { timeFormatter } from "@/utils/time.js";
import TagList from "@/components/tagList/TagList.vue";
import TagListItem from "@/components/tagList/TagListItem.vue";
import ProjectStatusTag from "@/components/base/ProjectStatusTag.vue";
import TextEditor from "@/components/base/TextEditor.vue";
import ProjectDialogManage from "./ProjectDialogManage.vue";
import ProjectDialogAddTag from "./ProjectDialogAddTag.vue";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { remindErrorMessage } from "@/utils/utils";
import VueCookies from "vue-cookies";
import { ROLE, ROLE_MAP } from "@/constant/role.js";
import {
  PROJECT_STATUS_MAP,
  PROJECT_STATUS,
  PROJECT_STATUS_OPTION,
} from "@/constant/project_status.js";
const router = useRouter();
const route = useRoute();

// #region 标签列表相关
const tagList = ref([]);
const tagListLoading = ref(false);
const TagListRef = ref(null);
//获取项目标签及对应的数量
const getTagList = async () => {
  let params = {
    // startTime: null,
    // endTime: null,
    // name: null,
  };
  let res = await httpPost("/projectTag/v1/searchProjectTagAndNum", params);
  if (res.code == 0) {
    tagList.value = res.data.map((item) => ({
      ...item,
      isChecked: false,
      onEditing: false,
    }));
    isAllChecked.value = true;
    checkedTagId.value = null;
  }
};
// 项目状态options
const projectStatusOptions = ref([]);
const projectStatus = ref(null);
// 有无策略
const role = ref(VueCookies.get("userInfo").spiderPermission?.role);
// TODO 测试用权限
// role.value = 1;
const ifShowSchedulePermission = computed(() => {
  return role.value != ROLE.PREMIUM && role.value != ROLE.CUSTOMIZED;
});
//选中标签
const isAllChecked = ref(true);
const checkedTagId = ref(null);
const resetCheckedTag = () => {
  // 重置被选中的tag
  const itemToUpdate = tagList.value.find((item) => item.isChecked);
  if (itemToUpdate) {
    itemToUpdate.isChecked = false;
  }
};
const handleClickTag = (item) => {
  resetCheckedTag();
  item.isChecked = true;
  isAllChecked.value = false;
  checkedTagId.value = item.uid;
  getProjectList();
};
const handleClickAllTag = () => {
  resetCheckedTag();
  isAllChecked.value = true;
  checkedTagId.value = null;
  getProjectList();
};
// 获取项目状态
const getProjectStatusList = () => {
  projectStatusOptions.value = PROJECT_STATUS_OPTION;
};
//删除标签
const handleClickDeleteTag = (item) => {
  confirmDeleteBox("此操作将删除标签「" + item.name + "」, 是否继续?").then(
    async () => {
      let params = {
        tagUid: item.uid,
      };
      // let res = await httpPost("/setting/group/delete/" + item.uid);
      let res = await httpPost("/projectTag/v1/deleteTag", params);
      if (res.code == 0) {
        getTagList();
        ElMessage.success("删除成功");
      } else {
        remindErrorMessage(res.message);
      }
    }
  );
};
// 新增标签
const handleClickAddTag = (value) => {
  if (value === "") {
    TagListRef.value.hideAddInput();
  } else if (value.length < 2) {
    ElMessage.warning("项目标签名称最短为2位");
  } else {
    checkAndSaveTag(null, value);
  }
};
//编辑标签
const tagItemRef = ref(null);
const handleClickEditTag = (item, index) => {
  item.onEditing = true;
  //聚焦对应输入框
  tagItemRef.value[index].focusInput();
};
//保存标签
const handleClickSaveTag = async (item, newVal, oldVal) => {
  if (newVal === "") {
    item.onEditing = false;
  } else if (newVal.length < 2) {
    ElMessage.warning("项目标签名称最短为2位");
  } else {
    if (oldVal != newVal) {
      if (item.uid) {
        renameTag(item.uid, newVal);
      } else {
        checkAndSaveTag(item.uid, newVal);
      }
    } else {
      item.onEditing = false;
    }
  }
};
//点击有效策略数量，跳转策略界面
const goSchedulePage = (item) => {
  console.log(item);
};
const renameTag = async (tagUid, name) => {
  let params = {
    tagUid,
    name,
  };
  let res = await httpPost("/projectTag/v1/renameTag", params);
  if (res.code == 0) {
    getTagList();
    ElMessage.success("修改成功");
  }
};
const checkAndSaveTag = async (id, value) => {
  saveTag(id, value);
  // }
};
const saveTag = Debounce(async (id, value) => {
  let params = {
    tagName: value,
  };
  let res = await httpPost("/projectTag/v1/addTag", params);
  if (res.code == 0) {
    getTagList();
    //如果是新增
    if (!id) {
      ElMessage.success("新增成功");
      TagListRef.value.hideAddInput();
    } else {
      ElMessage.success("编辑成功");
    }
  }
});
// #endregion 标签列表相关

// #region 表格数据相关
const keywords = ref("");
const projectList = ref([]);
const tableLoading = ref(false);
const getProjectList = async () => {
  tableLoading.value = true;
  // let params = {
  //   pageNum: pageNum.value,
  //   pageSize: pageSize.value,
  //   typeId: null,
  //   groupId: checkedTagId.value,
  //   startTime: null,
  //   endTime: null,
  //   name: keywords.value,
  // };
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    tagUid: checkedTagId.value,
    keyword: keywords.value,
    status: projectStatus.value,
  };
  let res = await httpPost("/project/v1/searchProjectByConditions", params);
  if (res.code == 0) {
    projectList.value = res.data.data;
    pageNum.value = res.data.pageNum;
    pageSize.value = res.data.pageSize;
    total.value = res.data.total;
  }
  tableLoading.value = false;
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getProjectList,
  ifFetchOnMounted: false,
});
// 控制列表的复选框是否禁用
const ifCheckboxSelectable = (row) => {
  if (row.createUser == 0) {
    return 0;
  } else {
    return 1;
  }
};
// #endregion 表格数据相关

// #region 表格批量操作相关
// 记录列表勾选后被选中的id
const selectedItemList = ref([]);
const handleSelectionChange = (val) => {
  selectedItemList.value = val;
};
const handleBatchCommand = (command) => {
  if (selectedItemList.value.length == 0) {
    ElMessage.warning("请先勾选项目！");
    return;
  }
  switch (command) {
    case "batchDelete":
      handleClickBatchDelete();
      break;
    case "batchAddTag":
      handleClickBatchAddTag();
      break;
  }
};
// 批量删除
const handleClickBatchDelete = () => {
  confirmDeleteBox("此操作将删除选中的项目, 是否继续?").then(async () => {
    let params = {
      projectUidList: selectedItemList.value.map((item) => item.projectUid),
    };
    let res = await httpPost("/project/v1/deleteBatchProject", params);
    if (res.code == 0) {
      initPage();
      ElMessage.success("删除成功");
    } else {
      remindErrorMessage(res.message);
    }
  });
};
// 批量添加标签
const projectDialogAddTagRef = ref(null);
const handleClickBatchAddTag = () => {
  projectDialogAddTagRef.value.openDLG(selectedItemList.value);
};
// 立即采集
// const handleClickCrawling = async () => {
//   let params = {
//     // projectId: projectId,
//   };
//   let res = await httpPost("/collect/v1/startCollect", params);
//   if (res.code == 0) {
//   }
// };
// 新建采集项目
const projectDialogManageRef = ref(null);
const handleClickAddProject = () => {
  projectDialogManageRef.value.openDLG("ADD");
};
// #endregion 表格批量操作相关

// #region 单个项目操作相关
const handleCommand = (scope, command) => {
  switch (command) {
    case "goTask":
      goTask(scope.row);
      break;
    case "goData":
      goData(scope.row);
      break;
    case "goLog":
      goLog(scope.row);
      break;
    case "goSchedule":
      goSchedule(scope.row);
      break;
    case "rename":
      handleClickRename(scope.row);
      break;
    case "delete":
      handleClickDelete(scope.row);
      break;
  }
};
//重命名
const handleClickRename = (row) => {
  row.onEditing = true;
};
//删除
const handleClickDelete = (row) => {
  confirmDeleteBox("此操作将删除项目「" + row.name + "」, 是否继续?").then(
    async () => {
      let res = await httpPost("/project/v1/deleteBatchProject", {
        projectUidList: [row.projectUid],
      });
      if (res.code == 0) {
        initPage();
        ElMessage.success("删除成功");
      } else {
        remindErrorMessage(res.message);
      }
    }
  );
};
// 保存重命名
const handleClickSaveName = Debounce(async (text, row) => {
  if (text === "") {
    ElMessage.warning("项目名称不能为空");
    row.ifShowLoading = false;
  } else if (text.length < 2) {
    ElMessage.warning("项目名称最短为2位");
    row.ifShowLoading = false;
  } else {
    let res = await saveProjectName(text, row);
    row.ifShowLoading = false;
  }
});
// 修改项目状态
const handleClickSetProjectStatus = async (row, status) => {
  let params = {
    projectUid: row.projectUid,
    status,
  };
  let res = await httpPost("/project/v1/updateProjectStatus", params);
  if (res.code == 0) {
    initPage();
    ElMessage.success("修改状态成功");
  }
};
const saveProjectName = async (text, row) => {
  let param = {
    uid: row.projectUid,
    name: text,
  };

  let res = await httpPost("/project/v1/renameProject", param);
  if (res.code == 0) {
    getProjectList();
    ElMessage.success("保存成功");
  }
  return res;
};
// 点击列表项名称
const handleClickName = (row) => {
  goOverview(row);
};
//前往概览页
const goOverview = (row) => {
  router.push({
    name: "ProjectOverview",
    params: {
      projectId: row.projectUid,
    },
  });
};
//前往爬虫页
const goSpider = (row) => {
  router.push({
    name: "ProjectSpider",
    params: {
      projectId: row.projectUid,
    },
  });
};
//前往任务页
const goTask = (row) => {
  router.push({
    name: "ProjectTaskview",
    params: {
      projectId: row.projectUid,
    },
  });
};
//前往数据页
const goData = (row) => {
  router.push({
    name: "ProjectData",
    params: {
      projectId: row.projectUid,
    },
  });
};
//前往日志页
const goLog = (row) => {
  router.push({
    name: "ProjectLogview",
    params: {
      projectId: row.projectUid,
    },
  });
};
//前往策略页
const goSchedule = (row) => {
  router.push({
    name: "ProjectSchedule",
    params: {
      projectId: row.projectUid,
    },
  });
};
// #endregion 单个项目操作相关

const initPage = async () => {
  getTagList();
  await getProjectList();
};

onMounted(async () => {
  getProjectStatusList();
  tagListLoading.value = true;
  await initPage();
  tagListLoading.value = false;
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
@import "@/assets/styles/components/page-option-bar.scss";
.page-option-bar {
  top: -75px;
  right: 0px;
}
.button-plain {
  background-color: #ffffff;
  border-color: $theme-color;
  color: $theme-color;
}
.skeleton-bar {
  width: 45%;
  height: 57px;
  display: flex;
  .skeleton-item {
    height: 33px;
    width: 100px;
    margin-bottom: 10px;
    margin-right: 15px;
  }
}
.option-bar {
  .option {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
</style>
