<template>
  <div class="project-index">
    <ProjectTable></ProjectTable>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, onMounted } from "vue";
import { httpPost } from "@/api/httpService.js";
import ProjectTable from "./components/ProjectTable.vue";

const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);
const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "项目",
      name: "ProjectIndex",
      params: {},
    },
  ];
  const pageTitle = "项目";
  emits("setBreadcrumbList", breadcrumbList);
  emits("setPageTitle", pageTitle);
};

onMounted(async () => {
  await setBreadcrumb();
});
</script>

<style lang="scss" scoped></style>
