<template>
  <div class="home-wrapper">
    <div class="left-container">
      <div style="height: 170px" class="left">
        <ProjectProcessCard
          :activeStep="0"
          @add-project="handleClickAddProject"
        />
      </div>
      <div style="height: 320px" class="left">
        <RecentProjectTable
          :data="recentTableList"
          :loading="recentTableLoading"
        />
      </div>
      <div style="height: 320px" class="left">
        <RecentTaskTable :data="recentTaskList" :loading="loading" />
      </div>
    </div>
    <div class="right-container">
      <div style="height: 170px" class="right">
        <ProjectAndCountCard :data="taskOverviewData" />
      </div>

      <div style="height: 170px" class="right">
        <TaskStatisticsCard :data="taskOverviewData" />
      </div>

      <div style="height: 470px" class="right">
        <CollectionCountCard
          @change="getOverviewTaskData"
          :loading="taskOveriewLoading"
          :dataList="taskOverviewData.collectTrend"
        />
      </div>
    </div>
    <ProjectDialogManage
      ref="projectDialogManageRef"
      width="40%"
      @submit="getRecentProjectList"
    />
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import ProjectProcessCard from "@/components/projectCard/ProjectProcessCard.vue";
import CollectionCountCard from "./components/CollectionCountCard.vue";
import RecentProjectTable from "./components/RecentProjectTable.vue";
import RecentTaskTable from "./components/RecentTaskTable.vue";
import ProjectAndCountCard from "./components/ProjectAndCountCard.vue";
import TaskStatisticsCard from "./components/TaskStatisticsCard.vue";
import ProjectDialogManage from "./../project/components/ProjectDialogManage.vue";

const loading = ref(false);
const data = ref({});
const getData = async () => {
  // loading.value = true;
  // let res = await httpPost("/project/v1/searchIndexInfo");
  // if (res.code == 0) {
  //   data.value = res.data;
  // }
  // loading.value = false;
  getRecentProjectList();
  getRecentTaskList();
};

const recentTableLoading = ref(false);
const recentTableList = ref([]);
const getRecentProjectList = async () => {
  recentTableLoading.value = true;

  let params = {
    pageNum: 1,
    pageSize: 5,
  };
  let res = await httpPost("/project/v1/searchProjectByConditions", params);
  if (res.code == 0) {
    recentTableList.value = res.data.data;
  }
  recentTableLoading.value = false;
};

const recentTaskLoading = ref(false);
const recentTaskList = ref([]);
const getRecentTaskList = async () => {
  recentTaskLoading.value = true;

  let params = {
    pageNum: 1,
    pageSize: 5,
  };
  let res = await httpPost("/task/v1/searchTaskList", params);
  if (res.code == 0) {
    recentTaskList.value = res.data.data;
  }
  recentTaskLoading.value = false;
};

const taskOverviewData = ref({});
const taskOveriewLoading = ref(false);
// 获取采集任务相关概览数据
const getOverviewTaskData = async (condition) => {
  taskOveriewLoading.value = true;
  let params = {
    type: 1, //1-首页/2-项目概览
    ...condition,
    // status: 1, //1-时/2-日/3-月
    // beginTime: null,
    // endTime: null,
  };
  let res = await httpPost("/project/v1/searchHomePageData", params);
  if (res.code == 0) {
    taskOverviewData.value = res.data;
  }
  taskOveriewLoading.value = false;
};

//创建项目项目
const projectDialogManageRef = ref(null);
const handleClickAddProject = () => {
  projectDialogManageRef.value.openDLG("ADD");
};

onMounted(() => {
  getData();
});
</script>

<style lang="scss" scoped>
.home-wrapper {
  $grid-gap-width: 20px;
  $columns: 2;
  display: grid;
  grid-gap: $grid-gap-width;
  grid-template-rows: 3;
  grid-template-columns: repeat(
    $columns,
    calc(100% / $columns - $grid-gap-width / $columns)
  );
  > div {
    width: 100%;
  }
  // display: flex;
  // gap: 20px;
  .left-container,
  .right-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
