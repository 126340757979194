<template>
  <div>
    <div class="reply-info" v-if="replyCount !== 0">
      <template
        v-for="comment in subCommentList.slice(0, 3)"
        :key="comment.uid"
      >
        <div class="reply-content">
          <div class="username">
            {{
              comment.answeredUserName !== null
                ? comment.userName + " 回复 " + " " + comment.answeredUserName
                : comment.userName
            }}:
          </div>
          <div class="content">{{ comment.content }}</div>
        </div>
        <div class="reply-bottom">
          <div class="time">{{ timeFormatter(comment.commentTime) }}</div>
          <span><i class="iconfont icon-dianzan"></i></span>
          <span class="reply" @click="openReplyInput(comment)">回复</span>
          <span
            class="delete"
            v-if="comment.isOwner === 1"
            @click="handleDeleteComment(comment.uid)"
          >
            删除
          </span>
        </div>
      </template>
      <div class="fold-comments" v-if="ifShowAllComments">
        <!-- 渲染剩余评论 -->
        <template v-for="comment in subCommentList.slice(3)" :key="comment.uid">
          <div class="reply-content">
            <div class="username">
              {{
                comment.answeredUserName !== null
                  ? comment.userName + " 回复 " + " " + comment.answeredUserName
                  : comment.userName
              }}:
            </div>
            <div class="content">{{ comment.content }}</div>
          </div>
          <div class="reply-bottom">
            <div class="time">{{ timeFormatter(comment.commentTime) }}</div>
            <span><i class="iconfont icon-dianzan"></i></span>
            <span class="reply" @click="openReplyInput(comment)">回复</span>
            <span
              class="delete"
              v-if="comment.isOwner === 1"
              @click="handleDeleteComment(comment.uid)"
            >
              删除
            </span>
          </div>
        </template>
      </div>
      <div class="total-reply">
        <span>共{{ replyCount }}条回复</span>
        <span v-if="replyCount > 3">
          ,
          <span @click="viewAllComment" class="reply-tip">
            {{ ifShowAllComments ? "收起" : "点击查看" }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, defineEmits, defineProps, computed } from "vue";
import { timeFormatter } from "@/utils/time.js";
import { httpPost } from "@/api/httpService.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { VueCookies } from "vue-cookies";
import { ElMessage } from "element-plus";
const ifShowAllComments = ref(false);
const replyInputRef = ref(null);
const commentCount = ref(1);
const replyContent = ref("");
const replyPlaceholder = ref("回复: 我是用户名");
const inputRow = ref(1);
const props = defineProps({
  subCommentList: {
    type: Array,
    default: () => [],
  },
});
const emits = defineEmits(["openReplyInput", "deleteComment"]);
const replyCount = computed(() => {
  return props.subCommentList.length;
});
const openReplyInput = (comment) => {
  emits("openReplyInput", comment);
};
const handleDeleteComment = (commentUid) => {
  confirmDeleteBox("是否确认删除该评论", "提示")
    .then(() => {
      onDeleteComment(commentUid);
    })
    .catch(() => {});
};
//查看所有评论
const viewAllComment = () => {
  ifShowAllComments.value = !ifShowAllComments.value;
};
// TODO 删除评论-待对接
const onDeleteComment = async (commentUid) => {
  emits("deleteComment", commentUid);
};
//TODO-点赞评论-待对接
const onLikeComment = () => {
  let params = {};
};
const adjustInputHeight = () => {
  inputRow.value = 4;
};
</script>

<style lang="scss" scoped>
.reply-info {
  margin-left: 150px;
  margin-top: 20px;
  padding: 10px 10px 10px 10px;
  background-color: rgba(239, 239, 239, 0.7);
  .reply-content {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 10px;
    .username {
      color: #9a9a9a;
      font-size: 14px;
    }
    .content {
      color: #6c6c6c;
      font-size: 14px;
    }
  }
  .reply-bottom {
    display: flex;
    align-items: center;
    gap: 25px;
    cursor: pointer;
    padding-bottom: 10px;
    color: #9a9a9a;
  }
  .total-reply {
    color: #9a9a9a;
    font-size: 14px;
    .reply-tip {
      color: $theme-color;
      cursor: pointer;
    }
  }
}
.reply-input {
  margin-left: 130px;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  .replyer-info {
    display: flex;
    align-items: center;
    gap: 5px;
    .title {
      color: #9a9a9a;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .input {
    width: 100%;
  }
}
</style>
