<template>
  <div class="list-table">
    <div class="table-wrapper">
      <el-table
        :data="spiderList"
        style="width: 100%"
        v-loading="tableLoading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="name" label="爬虫名称" />
        <!-- <el-table-column prop="spiderType" label="爬虫类型">
          <template #default="scope">
            {{
              scope.row.spiderType
                ? SPIDER_TYPE_MAP[scope.row.spiderType].label
                : "--"
            }}
          </template>
        </el-table-column> -->
        <el-table-column prop="createTime" label="创建时间">
          <template #default="scope">
            {{ timeFormatter(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间">
          <template #default="scope">
            {{ timeFormatter(scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="deleteTime" label="删除时间">
          <template #default="scope">
            {{ timeFormatter(scope.row.deleteTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="operate" label="操作">
          <template #default="{ row }">
            <span class="option" @click="handleClickRecover(row)">恢复</span>
            <span class="clear option" @click="handleClickCompleteDelete(row)">
              彻底删除
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          small
          background
          layout="total , prev, pager, next,sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { SPIDER_TYPE_MAP } from "@/constant/spider_type.js";
import { ref, nextTick, defineEmits, defineExpose } from "vue";
import { httpPost } from "@/api/httpService.js";
import { usePagination } from "@/utils/usePagination";
import { useRouter } from "vue-router";
import VueCookies from "vue-cookies";
import { timeFormatter } from "@/utils/time.js";

const emits = defineEmits(["select", "recover", "confirm-delete"]);

const tableLoading = ref(false);
const spiderList = ref([]);
const getDataList = async () => {
  tableLoading.value = true;
  const userId = VueCookies.get("userInfo").userId;
  // let params = {
  //   userId: userId,
  //   dataTypeId: null,
  //   keyword: null,
  //   status: null,
  //   ifMarket: 0,
  //   isMarket: 0,
  //   pageNum: pageNum.value,
  //   pageSize: pageSize.value,
  //   isDelete: 1,
  // };
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    spiderName: null,
  };
  let res = await httpPost(
    "/recycleBin/v1/searchSpiderRecycleBinByConditions",
    params
  );
  if (res.code == 0) {
    spiderList.value = res.data.data;
    pageNum.value = res.data.pageNum;
    pageSize.value = res.data.pageSize;
    total.value = res.data.total;
  }
  tableLoading.value = false;
};
// 记录列表勾选后被选中的id
const handleSelectionChange = (val) => {
  emits(
    "select",
    val.map((item) => item.spiderUid)
  );
};
//恢复
const handleClickRecover = (row) => {
  emits("recover", [row.spiderUid]);
};
//彻底删除
const handleClickCompleteDelete = (row) => {
  emits("confirm-delete", [row.spiderUid]);
};

const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getDataList,
});

defineExpose({ getDataList });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
.list-table {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  height: calc(100vh - 80px - 60px - 100px);
  .option {
    cursor: pointer;
    color: $theme-color;
  }
  .clear {
    margin-left: 20px;
  }
}
</style>
