<template>
  <div class="spider-page">
    <div class="search-spider">
      <div class="input-box">
        <el-input
          class="option input"
          clearable
          placeholder="请输入关键字"
          v-model="searchForm.keywords"
        ></el-input>
        <el-select
          v-model="searchForm.status"
          placeholder="请选择爬虫状态"
          class="option select"
          clearable
        >
          <el-option
            v-for="item in SPIDER_STATUS_OPTION"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button
          type="primary"
          class="option"
          @click="handleClickSearchSpider"
        >
          查询
        </el-button>
      </div>
    </div>
    <div class="spider-template-container" v-loading="pageLoading">
      <div v-if="spiderDataList.length > 0">
        <div class="spider-header">
          <div class="right-container">
            <div
              :class="['card-view', ifCardView ? 'active' : '']"
              @click="handleClickView(VIEW_TYPE.CARD)"
            >
              <i class="iconfont icon-qiapian"></i>
              <span class="view-type">卡片视图</span>
            </div>
            <div
              :class="['list-view', !ifCardView ? 'active' : '']"
              @click="handleClickView(VIEW_TYPE.LIST)"
            >
              <i class="iconfont icon-liebiao"></i>
              <span class="view-type">列表视图</span>
            </div>
          </div>
        </div>
        <div :class="['spider-content', ifbgcColor ? 'active' : '']">
          <div class="spider-card-body" v-if="ifCardView">
            <CardView
              :spiderDataList="spiderDataList"
              @refresh="getSpiderDataList"
            ></CardView>
          </div>

          <div class="spider-list-body" v-else>
            <ListView
              :spiderDataList="spiderDataList"
              @refresh="getSpiderDataList"
              @select-data="getSelectedItemList"
            ></ListView>
          </div>
          <div class="pagination-box">
            <el-pagination
              v-model:current-page="pageNum"
              v-model:page-size="pageSize"
              :page-sizes="[10, 20, 30, 40]"
              small
              background
              layout="total , prev, pager, next,sizes"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="empty-container"
      v-if="(!pageLoading && spiderDataList.length == 0) || ifSearchEmpty"
    >
      <EmptyStatus :size="350" :imageSrc="require('@/assets/imgs/empty2.png')">
        <template #description>
          <div v-if="!ifSearchEmpty">
            暂无我的爬虫，立即
            <span class="addSpider" @click="handleAddSpider">新建爬虫模板</span>
          </div>
          <div v-if="ifSearchEmpty" style="color: #6c6c6c">暂无搜索结果</div>
        </template>
      </EmptyStatus>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  nextTick,
  computed,
  onMounted,
  onBeforeUnmount,
  defineExpose,
} from "vue";
import { useRouter } from "vue-router";
import VueCookies from "vue-cookies";
import { httpPost, spiderDesignHost } from "@/api/httpService.js";
import { SPIDER_STATUS_OPTION } from "@/constant/spider_status.js";
import { usePagination } from "@/utils/usePagination";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { Debounce } from "@/utils/utils";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
import ListView from "./SpiderTemplateList.vue";
import CardView from "./SpiderTemplateCard.vue";
import { ElMessage } from "element-plus";

const router = useRouter();
const VIEW_TYPE = {
  CARD: 1,
  LIST: 2,
};
// const spiderType = ref("my-spider");
const cardType = ref(VIEW_TYPE.CARD);
const searchForm = ref({
  keywords: "",
  status: "",
});
const checkSearchForm = computed(() => {
  if (searchForm.value.keywords !== "" || searchForm.value.status !== "") {
    return true;
  } else {
    return false;
  }
});

const ifSearchEmpty = ref(false);
//#region  切换视图

//获取当前的视图
const handleClickView = (type) => {
  cardType.value = type;
};
//是否是卡片类型
const ifCardView = computed(() => {
  return cardType.value == VIEW_TYPE.CARD ? true : false;
});
//是否显示背景
const ifbgcColor = computed(() => {
  return cardType.value == VIEW_TYPE.CARD ? false : true;
});
//#endregion
//#region 获取爬虫列表

const pageLoading = ref(true);
// const ifLoading = ref(false);
const userId = ref(VueCookies.get("userInfo").userId);
const spiderDataList = ref([]);
const getSpiderDataList = async () => {
  pageLoading.value = true;
  // ifLoading.value = true;
  let params = {
    // userId: userId.value,
    // dataTypeId: 0,
    keywords: searchForm.value.keywords,
    status: searchForm.value.status,
    // ifMarket: 0,
    // isMarket: 0,
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    spiderStatus: status,
  };
  // TODO 重新对接-已完成
  let res = await httpPost("/spider/v1/searchSpiders", params);
  if (res.code == 0) {
    if (checkSearchForm.value && res.data.data.length === 0) {
      ifSearchEmpty.value = true;
    } else {
      ifSearchEmpty.value = false;
    }
    spiderDataList.value = res.data.data;
    console.log(checkSearchForm.value);
    total.value = res.data.total;
    pageNum.value = res.data.pageNum;
  }
  pageLoading.value = false;
};
//#endregion

//#region 多选,批量删除
const selectedList = ref([]);
const getSelectedItemList = (val) => {
  selectedList.value = val;
};
//批量删除
const handleClickBatchRemove = () => {
  if (selectedList.value == 0) {
    ElMessage.warning("请选择");
  } else {
    confirmDeleteBox("此操作将永久删除爬虫模板, 是否继续?").then(() => {});
  }
};
//#endregion
//查询
const handleClickSearchSpider = Debounce(() => {
  getSpiderDataList();
});

//前往数据结构模板管理
const gotoDataStructureManage = () => {
  router.push({
    path: "/data-structure-module",
  });
};
//新建爬虫模板
const handleAddSpider = () => {
  window.open(spiderDesignHost + `/spider/design/index.html`);
};

const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getSpiderDataList,
});

//监听爬虫设计器的postMessage
function handleReceiveMessage(event) {
  if (
    spiderDesignHost.indexOf(event.origin) != -1 &&
    event.data.name === "spider-design-refresh"
  ) {
    // console.log(event.data);
    // console.log(event.origin);
    // console.log(event.source);
    getSpiderDataList();
  }
}
defineExpose({
  getSpiderDataList,
});
onMounted(() => {
  //监听爬虫设计器的postMessage
  window.addEventListener("message", handleReceiveMessage);
});
onBeforeUnmount(() => {
  window.removeEventListener("message", handleReceiveMessage);
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/page-option-bar.scss";
.spider-page {
  min-height: $project-page-min-height;
}
/* .page-option-bar {
  position: absolute;
  top: -76px;
  right: 0px;
} */
.search-spider {
  position: absolute;
  top: -1px;
  left: 210px;
}
.input-box {
  display: flex;
  .option {
    margin-right: 24px;
  }
  .input {
    width: 240px;
  }
  .select {
    width: 160px;
  }
}
.spider-template-container {
  .spider-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 0;
    .left-container {
      display: flex;
      align-items: center;
      .line {
        height: 24px;
        border: 1px solid rgba(224, 227, 234, 1);
        margin: 0 20px;
      }
      .input-box {
        display: flex;
        .option {
          margin-right: 24px;
        }
        .input {
          width: 240px;
        }
        .select {
          width: 160px;
        }
      }
    }
    .right-container {
      display: flex;
      align-items: center;
      position: absolute;
      top: -2px;
      right: -20px;
      .card-view,
      .list-view {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104px;
        height: 30px;
        border-radius: 6px;
        color: rgba(108, 108, 108, 1);
        font-size: 13px;
        text-align: center;
        font-family: Roboto;
      }
      .active {
        background-color: rgba(226, 225, 225, 1);
      }
    }

    .el-radio-group {
      border-radius: 36px;
      box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
      :deep(.el-radio-button__inner) {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 36px;
        font-size: 13px;
        width: 80px;
        height: 32px;
        border: 0;
      }
    }
  }
  .spider-content {
    /* margin-top: 20px; */
    min-height: calc(100vh - 220px);
  }
  .view-type {
    margin-left: 5px;
  }
  .pagination-box {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
  .active {
    background-color: #fff;
  }
}
.empty-container {
  height: $project-page-min-height;
  .addSpider {
    color: $theme-color;
    cursor: pointer;
  }
}
</style>
