<template>
  <div class="tag-bar">
    <div class="tag-add" v-if="addable">
      <slot></slot>

      <el-input
        v-if="inputAddVisible"
        ref="InputAddRef"
        class="tag-input"
        size="default"
        v-model="inputAddValue"
        @keyup.enter="handleAddTag"
        style="margin-left: 0px"
      />
      <el-button
        v-else
        class="tag-button"
        @click="showAddInput"
        style="margin-left: 0px"
      >
        <i class="iconfont icon-plus icon-jiahao" />
        {{ addLabel }}
      </el-button>
    </div>
    <div></div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  computed,
  nextTick,
  onMounted,
  defineExpose,
} from "vue";

const emits = defineEmits(["tag-add"]);

const props = defineProps({
  addable: {
    type: Boolean,
    default: () => false,
  },
  addLabel: {
    type: String,
    default: () => "",
  },
});

//新增分类标签相关
const inputAddValue = ref("");
const inputAddVisible = ref(false);
const InputAddRef = ref(null);
const showAddInput = () => {
  inputAddValue.value = "";
  inputAddVisible.value = true;
  nextTick(() => {
    InputAddRef.value.input.focus();
  });
};
const hideAddInput = () => {
  inputAddValue.value = "";
  inputAddVisible.value = false;
};
const handleAddTag = () => {
  emits("tag-add", inputAddValue.value);
};
defineExpose({ hideAddInput });
</script>

<style lang="scss" scoped>
.tag-bar {
  min-height: 47px;
  display: flex;
  align-items: center;
  flex: 1 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .tag-add {
    // display: flex;
    width: 100%;
    align-items: center;

    .tag-input {
      width: 100px;
      height: 33px;
      margin-bottom: 10px;
      position: relative;
      top: 3px;
    }
    .tag-button {
      width: 100px;
      height: 33px;
      margin-bottom: 10px;
      background-color: #ffffff;
      border: 1px solid $theme-color;
      color: $theme-color;
      font-family: "Microsoft YaHei";
      position: relative;
      top: 3px;
      .icon-plus {
        margin-right: 5px;
        font-size: 12px;
      }
    }
    .tag-button:hover {
      background-color: #f2ecfe;
    }
  }
}
</style>
