<template>
  <div class="spider-card" @click="gotoDetail">
    <div class="card-header">
      <!-- <el-tag
        class="publish"
        size="small"
        color="#3894FF26"
        v-if="cardData.isMarket == 1"
      >
        发
      </el-tag>
      <el-tag
        class="buy"
        size="small"
        type="success"
        v-if="cardData.isMarket == 2"
      >
        购
      </el-tag> -->
      <div class="spider-name" v-showTip>
        <el-tooltip placement="bottom-start" effect="light">
          <template #content>
            {{ cardData.name }}
          </template>
          <div class="spider-text">
            <span class="ellipse-text">
              {{ cardData.name }}
            </span>
          </div>
        </el-tooltip>
      </div>
      <div class="spider-status">
        <SpiderStatusTag :status="cardData.spiderStatus"></SpiderStatusTag>
      </div>
    </div>
    <div class="card-content">
      <!-- <div>
        爬虫类型：
        {{
          cardData.spiderType
            ? SPIDER_TYPE_MAP[cardData.spiderType].label
            : "--"
        }}
      </div> -->
      <div class="desc">开始时间：{{ timeFormatter(cardData.createTime) }}</div>
      <div>更新时间：{{ timeFormatter(cardData.updateTime) }}</div>
    </div>
    <div class="card-footer">
      <el-button class="card-button" @click.stop="gotoDetail">详情</el-button>
      <el-button
        class="card-button"
        v-if="cardData.spiderStatus !== SPIDER_STATUS.ENABLED"
        @click.stop="handleClickOperate('enable')"
      >
        启用
      </el-button>
      <el-button
        class="card-button"
        v-if="cardData.spiderStatus === SPIDER_STATUS.ENABLED"
        @click.stop="handleClickOperate('disable')"
      >
        禁用
      </el-button>
      <el-button class="more">
        <el-dropdown ref="dropdown1" placement="bottom">
          <div class="more-icon" @click.stop>
            <i class="iconfont icon-gengduo"></i>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <!-- <el-dropdown-item>版本记录</el-dropdown-item> -->
              <!-- <el-dropdown-item
                @click="handleclickPublish"
                v-if="ifShowPublish(cardData)"
              >
                发布
              </el-dropdown-item> -->
              <el-dropdown-item
                v-if="cardData.spiderStatus === SPIDER_STATUS.DRAFT"
                @click="handleClickOperate('disable')"
              >
                禁用
              </el-dropdown-item>
              <el-dropdown-item
                v-if="cardData.spiderStatus !== SPIDER_STATUS.DRAFT"
                @click="handleClickOperate('backToDraft')"
              >
                退回到草稿
              </el-dropdown-item>
              <el-dropdown-item @click="handleClickDelete">
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from "vue";

import SpiderStatusTag from "@/components/base/SpiderStatusTag.vue";

import { timeFormatter } from "@/utils/time.js";
import {
  SPIDER_STATUS_MAP,
  SPIDER_STATUS,
  getStatusAction,
} from "@/constant/spider_status.js";
import { SPIDER_TYPE_MAP } from "@/constant/spider_type.js";

const emits = defineEmits(["publish", "delete", "go", "operate"]);
const props = defineProps({
  cardData: {
    type: Object,
  },
});
//是否显示更多里面的发布
const ifShowPublish = (row) => {
  if (row.isMarket == 0) {
    if (row.spiderStatus != SPIDER_STATUS.NOTTESTED) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
const handleClickDelete = () => {
  emits("delete");
};
const handleclickPublish = () => {
  emits("publish");
};
const gotoDetail = () => {
  emits("go");
};
const handleClickOperate = (operate) => {
  emits("operate", operate);
};
</script>

<style lang="scss" scoped>
.spider-card {
  height: 206px;
  padding: 16px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  transition: box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .spider-name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .spider-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ellipse-text,
    .title {
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      text-align: left;
      font-family: SourceHanSansSC-bold;
      font-weight: 550;
    }
    .buy,
    .publish {
      width: 22px;
      height: 22px;
      margin-right: 6px;
    }
    .publish {
      color: #1a78ff;
      border-color: #3894ff26;
    }
    .spider-status {
      margin-left: 20px;
    }
  }
  .card-content {
    margin-top: 20px;
    margin-bottom: 16px;
    color: rgba(108, 108, 108, 1);
    font-size: 14px;
    text-align: left;
    font-family: SourceHanSansSC-regular;
    .item {
      display: flex;
      .count {
        margin-left: 50px;
      }
    }
    .desc {
      padding: 16px 0;
    }
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    .el-button {
      height: 36px;
      line-height: 20px;
      border-radius: 8px;
      background-color: rgba(247, 247, 247, 1);
      color: rgba(181, 180, 180, 1);
      font-size: 14px;
      text-align: center;
      border: 0;
      font-family: Roboto;
    }
    .el-button:hover {
      background-color: rgba(127, 68, 240, 0.06);
      color: rgba(127, 68, 240, 1);
    }
    .card-button {
      flex: 1;
    }

    .more {
      width: 40px;
      height: 36px;
      .more-icon {
        width: 40px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        color: rgba(181, 180, 180, 1);
      }
    }
    .more:hover {
      .more-icon {
        color: rgba(127, 68, 240, 1);
      }
    }
  }
}
.spider-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
  transform: translateY(-10px);
}
</style>
