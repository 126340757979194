<template>
  <div
    class="card-container"
    v-loading="loading"
    :style="{ 'min-height': loading ? '400px' : '' }"
  >
    <!-- {{ data }} -->
    <el-row :gutter="20" v-show="data?.length > 0">
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="6"
        :xl="4"
        v-for="item in data"
        :key="item"
      >
        <div class="card-box">
          <ScheduleCard :cardData="item" v-bind="$attrs"></ScheduleCard>
        </div>
      </el-col>
    </el-row>
    <div class="empty-container">
      <EmptyStatus
        v-show="!ifFirst && data.length == 0"
        :imageSrc="require('@/assets/imgs/empty2.png')"
        :size="300"
      >
        <template #description>
          <slot name="empty">
            <div style="font-size: 18px; margin-bottom: 12px">
              暂无策略，立即
              <span class="description-button" @click="handleClickAddSchedule">
                新建策略
              </span>
            </div>
            <div style="font-size: 16px">
              策略用来设置采集项目的执行时间，比如可以设置项目定时采集、循环采集等。
            </div>
          </slot>
        </template>
      </EmptyStatus>
    </div>
    <!-- <SpiderTemplateDrawerPublish
      ref="spiderTemplateDrawerPublishRef"
      @refresh="updateSpider"
    ></SpiderTemplateDrawerPublish> -->
  </div>
</template>

<script setup>
import {
  ref,
  defineExpose,
  defineProps,
  defineEmits,
  nextTick,
  onMounted,
  onBeforeUnmount,
  watch,
} from "vue";
import { httpPost, spiderDesignHost } from "@/api/httpService.js";
import * as ws from "@/utils/websocket";

// import SpiderTemplateDrawerPublish from "./SpiderTemplateDrawerPublish.vue";
import ScheduleCard from "./ScheduleCard.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";

import {
  SCHEDULE_STATUS_MAP,
  SCHEDULE_STATUS,
} from "@/constant/schedule_status.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { remindErrorMessage } from "@/utils/utils";

import { ElMessage } from "element-plus";
import { ElLoading } from "element-plus";
const emits = defineEmits(["refresh", "add"]);
const props = defineProps({
  data: {
    type: Array,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
watch(
  () => props.loading,
  (newVal) => {
    console.log("watch iffirst", newVal);
    if (newVal == false) {
      ifFirst.value = false;
    }
  }
);
const ifFirst = ref(true);

const handleClickAddSchedule = () => {
  emits("add");
};

onMounted(() => {
  //监听爬虫设计器的postMessage
  //   window.addEventListener("message", handleReceiveMessage);
});
onBeforeUnmount(() => {
  //   window.removeEventListener("message", handleReceiveMessage);
});
</script>

<style lang="scss" scoped>
.card-box {
  margin-bottom: 20px;
}
.pagination-box {
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
}
.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
