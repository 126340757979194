<template>
  <div class="overview-card" v-loading="loading">
    <div class="header">
      <div class="title">最近项目</div>
      <div class="option" @click="goTask" v-if="tableData.length > 0">更多</div>
    </div>
    <div class="main" v-if="tableData.length > 0">
      <div class="main-table">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="项目名称">
            <template #default="scope">
              <div class="colunm-name">
                <i class="iconfont icon-wodexiangmu column-id"></i>
                <el-tooltip placement="bottom-start" effect="light">
                  <template #content>
                    {{ scope.row.name }}
                  </template>
                  <span @click="gotoProjectDetail(scope.row)">
                    {{ scope.row.name }}
                  </span>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="项目状态" width="100">
            <template #default="scope">
              <ProjectStatusTag :status="scope.row.status" />
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" width="130">
            <template #default="scope">
              {{ timeFormatter(scope.row.updateTime) }}
            </template>
          </el-table-column>

          <el-table-column prop="collectNum" label="采集数量" width="80">
            <template #default="scope">
              {{ scope.row.collectNum || "--" }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="empty" v-if="!loading && tableData.length === 0">
      <EmptyStatus :imageSrc="require('@/assets/imgs/empty1.png')">
        <template #description>
          <div class="empty-text">暂无内容</div>
        </template>
      </EmptyStatus>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, defineProps, defineEmits } from "vue";

import EmptyStatus from "@/components/base/EmptyStatus.vue";
import ProjectStatusTag from "@/components/base/ProjectStatusTag.vue";

import { useRouter } from "vue-router";

import { timeFormatter } from "@/utils/time.js";
const router = useRouter();
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const tableData = ref([]);

watch(
  () => props.data,
  (newVal, oldVal) => {
    tableData.value = newVal;
  },
  { immediate: true, deep: true }
);
const goTask = () => {
  router.push({
    name: "ProjectIndex",
  });
};
const gotoProjectDetail = (row) => {
  router.push({
    name: "ProjectOverview",
    params: {
      projectId: row.projectUid,
    },
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/overview-card.scss";
.column-id {
  color: $theme-color;
  margin-right: 5px;
}
.main-table {
  height: 240px;
  overflow: hidden;
  .colunm-name {
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
