<template>
  <div>
    <div class="page-option-bar">
      <!-- <span>
        <el-button type="" @click="handleClickBatchRemove">批量删除</el-button>
      </span> -->
      <span>
        <el-button @click="gotoDataStructureManage" class="button-plain">
          数据结构模板管理
        </el-button>
      </span>
      <span>
        <el-button type="primary" @click="handleAddSpider">
          新建爬虫模板
        </el-button>
      </span>
    </div>
    <div class="spider-header">
      <div class="left-container">
        <el-radio-group
          type="button"
          size="small"
          v-model="spiderType"
          @change="changeSpiderType"
        >
          <el-radio-button :label="SPIDER_TEMPLATE_TYPE.MYSPIDER">
            我的爬虫
          </el-radio-button>
          <el-radio-button :label="SPIDER_TEMPLATE_TYPE.SPIDER_MARKET">
            爬虫市场
          </el-radio-button>
        </el-radio-group>
        <div class="line"></div>
      </div>
    </div>
  </div>
  <MySpiderTemplate
    ref="mySpiderTemplateRef"
    v-if="spiderType === SPIDER_TEMPLATE_TYPE.MYSPIDER"
  />
  <SpiderMarket
    ref="spiderMarketRef"
    v-if="spiderType === SPIDER_TEMPLATE_TYPE.SPIDER_MARKET"
  />
</template>

<script setup>
import MySpiderTemplate from "./MySpiderTemplate.vue";
import SpiderMarket from "./SpiderMarket.vue";
import { httpPost, spiderDesignHost } from "@/api/httpService.js";
import { useRouter } from "vue-router";
import { SPIDER_STATUS_OPTION } from "@/constant/spider_status.js";
import { ref } from "vue";
const router = useRouter();
const SPIDER_TEMPLATE_TYPE = {
  MYSPIDER: 0,
  SPIDER_MARKET: 1,
};
const spiderMarketRef = ref(null);
const mySpiderTemplateRef = ref(null);
//前往数据结构模板管理
const gotoDataStructureManage = () => {
  router.push({
    path: "/data-structure-module",
  });
};
const spiderType = ref(SPIDER_TEMPLATE_TYPE.MYSPIDER);
const changeSpiderType = (val) => {
  spiderType.value = val;
};
//新建爬虫模板
const handleAddSpider = () => {
  window.open(spiderDesignHost + `/spider/design/index.html`);
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/page-option-bar.scss";

.page-option-bar {
  position: absolute;
  top: -76px;
  right: 0px;
}
.spider-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .line {
      height: 24px;
      border: 1px solid rgba(224, 227, 234, 1);
      margin: 0 20px;
    }
    .el-radio-group {
      border-radius: 36px;
      box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
      background-color: #fff;
      :deep(.el-radio-button__inner) {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 36px;
        font-size: 13px;
        width: 80px;
        height: 32px;
        border: 0;
      }
    }
  }
}
</style>
