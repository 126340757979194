<template>
  <div class="task-page">
    <div class="task-header" v-if="taskDataList?.length > 0 || checkSearchForm">
      <div class="header">
        <div class="header-item policy-name">
          项目名称：
          <!-- TODO-任务记录项目名称下拉选项 -->
          <!-- <el-input
            v-model="searchForm.projectName"
            placeholder="请输入项目名称"
            clearable
            @clear="handleClickClear"
          ></el-input> -->
          <el-select
            v-model="searchForm.projectUid"
            filterable
            remote
            reserve-keyword
            placeholder="请输入项目名称"
            clearable
          >
            <el-option
              v-for="item in projectNameOptions"
              :key="item.projectUid"
              :label="item.projectName"
              :value="item.projectUid"
            ></el-option>
          </el-select>
        </div>
        <div class="header-item policy-name" v-if="ifSenior">
          策略名称：
          <el-select
            v-model="searchForm.scheduleUid"
            filterable
            remote
            reserve-keyword
            :remote-method="remoteMethod"
            placeholder="请输入策略名称"
            clearable
          >
            <el-option
              v-for="item in scheduleOptions"
              :key="item.scheduleUid"
              :label="item.scheduleName"
              :value="item.scheduleUid"
            />
          </el-select>
        </div>
        <div class="header-item">
          任务ID：
          <el-select
            v-model="searchForm.taskId"
            placeholder="请选择任务ID"
            clearable
            filterable
            @clear="handleClickClear"
          >
            <el-option
              v-for="item in taskIdOptions"
              :key="item.taskUid"
              :label="item.taskNumber"
              :value="item.taskUid"
            />
          </el-select>
        </div>
      </div>
      <div class="header">
        <div
          class="header-item"
          :style="{
            marginRight: ifSenior ? '52px' : '38px',
          }"
        >
          创建时间：
          <el-date-picker
            :prefix-icon="customPrefix"
            v-model="searchForm.timeRanges"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            clearable
            @clear="handleClickClear"
          ></el-date-picker>
        </div>
        <div class="header-item">
          状态：
          <el-select
            v-model="searchForm.status"
            placeholder="请选择任务状态"
            clearable
            @clear="handleClickClear"
          >
            <el-option
              v-for="item in TASK_STATUS_OPTION"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <el-button type="primary" @click="getTaskDataList">查询</el-button>
        <el-button
          type="primary"
          plain
          class="button-plain"
          @click="handleClickReset"
        >
          清空
        </el-button>
      </div>
    </div>

    <div
      class="task-content"
      v-if="taskDataList?.length > 0 || checkSearchForm"
    >
      <div class="list-table">
        <el-table
          :data="taskDataList"
          style="width: 100%"
          v-loading="ifLoading"
        >
          <el-table-column prop="taskNumber" label="任务ID" width="150px">
            <template #default="{ row }">
              <span>{{ row.taskNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="projectName"
            label="项目名称"
            min-width="190px"
          />
          <el-table-column
            v-if="ifSenior"
            prop="scheduleName"
            label="策略名称"
            min-width="200px"
          >
            <template #default="scope">
              <span>{{ scope.row.scheduleName ?? "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="180">
            <template #default="scope">
              {{ timeFormatterDetail(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="completeTime" label="完成时间" min-width="180">
            <template #default="scope">
              {{ timeFormatterDetail(scope.row.completeTime) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="collectNum"
            label="采集数量"
            min-width="100px"
          />
          <el-table-column
            prop="status"
            label="状态"
            width="90px"
            fixed="right"
          >
            <template #default="scope">
              <el-tooltip
                effect="light"
                placement="bottom"
                content="当前项目有任务排队中/进行中，策略生成任务失败"
                v-if="scope.row.status === TASK_STATUS_TYPE.FAILED"
              >
                <TaskStatus :status="scope.row.status"></TaskStatus>
              </el-tooltip>
              <TaskStatus :status="scope.row.status" v-else></TaskStatus>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作"
            width="120px"
            fixed="right"
          >
            <template #default="scope">
              <div class="list-item-btn">
                <el-button link type="primary" @click="gotoDataPage(scope.row)">
                  数据
                </el-button>
                <el-button
                  link
                  v-if="
                    scope.row.status == TASK_STATUS_TYPE.QUEUEING ||
                    scope.row.status == TASK_STATUS_TYPE.IN_PROGRESS
                  "
                  type="primary"
                  @click="endTask(scope.row)"
                >
                  结束
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          small
          background
          layout="total , prev, pager, next,sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        />
      </div>
    </div>
    <div
      class="empty-container"
      v-if="!pageLoading && taskDataList?.length == 0 && !checkSearchForm"
    >
      <EmptyStatus :size="350" :imageSrc="require('@/assets/imgs/empty2.png')">
        <template #description>
          <div>暂无任务</div>
        </template>
      </EmptyStatus>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, shallowRef, h, onMounted, inject } from "vue";
import { httpPost } from "@/api/httpService.js";
import VueCookies from "vue-cookies";
import { useRouter } from "vue-router";
import TaskStatus from "@/components/base/TaskStatus.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";

import { timeFormatterDetail } from "@/utils/time.js";
import { usePagination } from "@/utils/usePagination";
import {
  TASK_STATUS_OPTION,
  TASK_STATUS_TYPE,
} from "@/constant/task_status.js";
import { useRoute } from "vue-router";
import { ROLE, ROLE_MAP } from "@/constant/role.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { ElMessage } from "element-plus";

const router = useRouter();
const route = useRoute();

const role = ref(VueCookies.get("userInfo").spiderPermission?.role);

const ifSenior = computed(() => {
  return role.value == ROLE.PREMIUM || role.value == ROLE.CUSTOMIZED;
});
//设置日期选择器图标
const customPrefix = shallowRef({
  render() {
    return h(
      "p",
      <el-icon>
        <Clock />
      </el-icon>
    );
  },
});

const searchForm = ref({
  projectName: "",
  scheduleUid: "",
  taskId: "",
  timeRanges: [],
  status: "",
});
//是否无筛选
const checkSearchForm = computed(() => {
  if (
    searchForm.value.projectName !== "" ||
    searchForm.value.scheduleName !== "" ||
    searchForm.value.timeRanges.length > 0 ||
    searchForm.value.taskId !== "" ||
    searchForm.value.status !== ""
  ) {
    return true;
  } else {
    return false;
  }
});

//TODO: 查询
const taskIdOptions = ref([]);
const projectNameOptions = ref([]);
const scheduleOptions = ref([]);
const ifLoading = ref(false);
// const pageLoading = ref(true);
const taskDataList = ref([]);
const getTaskDataList = async () => {
  // if (role.value === ROLE.TRIAL || role.value === ROLE.STANDARD) return;
  // pageLoading.value = true;
  ifLoading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    beginTime: searchForm.value.timeRanges[0] ?? "",
    endTime: searchForm.value.timeRanges[1] ?? "",
    scheduleUids: searchForm.value.scheduleUid
      ? [searchForm.value.scheduleUid]
      : [],
    projectUids: searchForm.value.projectUid
      ? [searchForm.value.projectUid]
      : [],
    status: searchForm.value.status,
    taskUid: searchForm.value.taskId,
  };
  let res = await httpPost("/task/v1/searchTasksByConditions", params);
  if (res.code == 0) {
    taskDataList.value = res.data.data;
    // projectNameOptions.value = res.data.data;
    pageNum.value = res.data.pageNum;
    total.value = res.data.total;
    // taskIdOptions.value = taskDataList.value?.map((item) => {
    //   return {
    //     label: item.taskUid,
    //     value: item.taskUid,
    //   };
    // });
  }
  ifLoading.value = false;
  // pageLoading.value = false;
};
// 获取策略列表
const getScheduleList = async () => {
  // if (role.value === ROLE.TRIAL) return;
  const res = await httpPost("/schedule/v1/getScheduleList");
  if (res.code == 0) {
    scheduleOptions.value = res.data;
  }
};
// 获取项目所有任务uid--待测试
const getProjectTaskNumber = async () => {
  let res = await httpPost("/task/v1/searchProjectTaskNumber");
  if (res.code == 0) {
    taskIdOptions.value = res.data;
  }
};

//远程搜索项目名称
const loadingOption = ref(false); //是否正在加载选项数据
const remoteMethodProjectName = async (value) => {
  // if (value) {
  loadingOption.value = true;
  // searchForm.value.projectName = value;
  let param = {};
  let res = await httpPost("/project/v1/searchProjectName", param);
  if (res.code == 0) {
    projectNameOptions.value = res.data;
  }
  console.log("value111", value);
  loadingOption.value = false;
  // } else {
  // searchForm.value.projectName = "";
  loadingOption.value = false;
  // }
};
// const handleSelectChange = (value) => {
//   searchForm.value.projectName = value;
// };
// const handleBlurSelect = (e) => {
//   let value = e.target.value; // 输入框值
//   if (value) {
//     searchForm.value.projectName = value;
//   }
// };
//前往数据页面
const gotoDataPage = (row) => {
  router.push({
    name: "ProjectData",
    params: {
      projectId: row.projectUid,
    },
    query: {
      taskId: row.taskUid,
    },
  });
};
const endTask = (row) => {
  confirmDeleteBox("确认结束排队中的采集任务吗？").then(async () => {
    let params = {
      taskUid: row.taskUid,
    };
    let res = await httpPost("/collect/v1/finishCollectTask", params);
    if (res.code == 0) {
      ElMessage.success("结束任务指令发送成功");
    }
    getTaskDataList();
  });
};
//清空
const handleClickReset = () => {
  searchForm.value.projectName = "";
  searchForm.value.scheduleUid = "";
  searchForm.value.taskId = "";
  searchForm.value.status = "";
  searchForm.value.timeRanges = [];
  getTaskDataList();
};
const handleClickClear = async () => {
  if (
    searchForm.value.projectName === "" &&
    searchForm.value.scheduleName === "" &&
    searchForm.value.taskId === "" &&
    searchForm.value.status === "" &&
    searchForm.value.timeRanges.length === 0
  ) {
    await getTaskDataList();
  }
};
onMounted(() => {
  remoteMethodProjectName();
  if (ifSenior.value) {
    getScheduleList();
  }
  getProjectTaskNumber();
});
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getTaskDataList,
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";

.task-page {
  min-height: $project-page-min-height;
}
.task-header {
  .button-plain {
    background-color: #ffffff;
    border-color: $theme-color;
    color: $theme-color;
  }
  :deep(.el-range-editor) {
    width: 250px;
  }
  .header {
    display: flex;
    margin-bottom: 24px;
  }
  .header-item {
    margin-right: 24px;
    color: rgba(108, 108, 108, 1);
    font-size: 14px;
    font-family: SourceHanSansSC-regular;
    :deep(.el-select) {
      width: 160px;
    }
  }
  .policy-name {
    :deep(.el-select) {
      width: 250px;
    }
    :deep(.el-input) {
      width: 250px;
    }
  }
  .policy-name:deep(.el-select .el-input__inner) {
    cursor: text;
  }
}
.task-content {
  margin-top: 24px;
  .list-table {
    :deep(.el-table .el-table__cell) {
      height: 48px;
    }
    .edit {
      color: $theme-color;
      // color: #000;
    }
    .option {
      cursor: pointer;
    }
  }
  .pagination-box {
    display: flex;
    margin-top: 24px;
    justify-content: flex-end;
  }
}

.empty-container {
  height: $project-page-min-height;
}
</style>
