<template>
  <div class="page-option-bar">
    <span>
      <el-button type="" @click="handleClickBatchDelete">彻底删除</el-button>
    </span>
    <span>
      <el-button
        @click="handleClickBatchRecover"
        class="button-plain"
        type="primary"
      >
        恢复
      </el-button>
    </span>
  </div>
  <div class="recycle-bin-header">
    <el-radio-group
      type="button"
      size="small"
      v-model="recycleType"
      @change="changeRecycleType"
    >
      <el-radio-button :label="RECYCBIN_TYPE.PROJECT">项目</el-radio-button>
      <el-radio-button :label="RECYCBIN_TYPE.MY_SPIDER">
        我的爬虫
      </el-radio-button>
    </el-radio-group>
  </div>
  <ProjectTable
    ref="projectRef"
    v-if="recycleType == RECYCBIN_TYPE.PROJECT"
    @select="handleSelectionChangeProject"
    @recover="recoverProject"
    @confirm-delete="handleConfirmDeleteProject"
  ></ProjectTable>
  <MySpiderTableVue
    ref="spiderRef"
    v-if="recycleType == RECYCBIN_TYPE.MY_SPIDER"
    @select="handleSelectionChangeSpider"
    @recover="recoverSpider"
    @confirm-delete="handleConfirmDeleteSpider"
  ></MySpiderTableVue>
</template>

<script setup>
import { ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { SPIDER_STATUS_OPTION } from "@/constant/spider_status.js";
import { ElMessage } from "element-plus";
import { httpPost, baseUrl } from "@/api/httpService.js";
import { remindErrorMessage } from "@/utils/utils";
import ProjectTable from "./ProjectTable.vue";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import MySpiderTableVue from "./MySpiderTable.vue";

//切换回收站类型
const RECYCBIN_TYPE = {
  PROJECT: 0,
  MY_SPIDER: 1,
};
const recycleType = ref(RECYCBIN_TYPE.PROJECT);
const changeRecycleType = (val) => {
  recycleType.value = val;
};

const projectRef = ref(null);
const spiderRef = ref(null);
const selectedProjectList = ref([]);
const selectedSpiderList = ref([]);
const handleSelectionChangeProject = (val) => {
  selectedProjectList.value = val;
};
const handleSelectionChangeSpider = (val) => {
  selectedSpiderList.value = val;
};
//恢复
const handleClickBatchRecover = () => {
  if (recycleType.value === RECYCBIN_TYPE.PROJECT) {
    if (selectedProjectList.value.length == 0) {
      ElMessage.warning("请先勾选项目！");
      return;
    }
    recoverProject(selectedProjectList.value);
  } else {
    if (selectedSpiderList.value.length == 0) {
      ElMessage.warning("请先勾选爬虫模板！");
      return;
    }
    recoverSpider(selectedSpiderList.value);
  }
};
const recoverProject = async (uidList) => {
  let params = {
    projectUidList: uidList,
  };
  let res = await httpPost("/recycleBin/v1/recoverProject", params);
  if (res.code == 0) {
    projectRef.value.getDataList();
    ElMessage.success("恢复成功");
  } else {
    remindErrorMessage(res.message);
  }
};
const recoverSpider = async (uidList) => {
  let params = {
    spiderUidList: uidList,
  };
  let res = await httpPost("/recycleBin/v1/recoverSpider", params);
  if (res.code == 0) {
    spiderRef.value.getDataList();
    ElMessage.success("恢复成功");
  } else {
    remindErrorMessage(res.message);
  }
};
//彻底删除
const handleClickBatchDelete = () => {
  if (recycleType.value === RECYCBIN_TYPE.PROJECT) {
    if (selectedProjectList.value.length == 0) {
      ElMessage.warning("请先勾选项目！");
      return;
    }
    confirmDeleteBox("此操作将彻底删除这些选中的项目, 是否继续?").then(
      async () => {
        confirmDeleteProject(selectedProjectList.value);
      }
    );
  } else {
    if (selectedSpiderList.value.length == 0) {
      ElMessage.warning("请先勾选爬虫模板！");
      return;
    }
    confirmDeleteBox("此操作将彻底删除这些选中的爬虫模板, 是否继续?").then(
      async () => {
        confirmDeleteSpider(selectedSpiderList.value);
      }
    );
  }
};
const handleConfirmDeleteProject = (idList) => {
  confirmDeleteBox("此操作将彻底删除该项目, 是否继续?").then(async () => {
    confirmDeleteProject(idList);
  });
};
const handleConfirmDeleteSpider = (idList) => {
  confirmDeleteBox("此操作将彻底删除该爬虫模板, 是否继续?").then(async () => {
    confirmDeleteSpider(idList);
  });
};
const confirmDeleteProject = async (uidList) => {
  console.log(uidList);
  let params = {
    projectUidList: uidList,
  };
  let res = await httpPost("/recycleBin/v1/completelyDeleteProject", params);
  if (res.code == 0) {
    projectRef.value.getDataList();
    ElMessage.success("删除成功");
  } else {
    remindErrorMessage(res.message);
  }
};
const confirmDeleteSpider = async (uidList) => {
  let params = {
    spiderUidList: uidList,
  };
  let res = await httpPost("/recycleBin/v1/completelyDeleteSpider", params);
  if (res.code == 0) {
    spiderRef.value.getDataList();
    ElMessage.success("删除成功");
  } else {
    remindErrorMessage(res.message);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/page-option-bar.scss";
@import "@/assets/styles/components/list-table.scss";
.page-option-bar {
  position: absolute;
  top: -77px;
  right: 0px;
}

.recycle-bin-header {
  .el-radio-group {
    border-radius: 36px;
    box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
    background-color: #fff;
    :deep(.el-radio-button__inner) {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 36px;
      font-size: 13px;
      width: 80px;
      height: 32px;
      border: 0;
    }
  }
  .active {
    background-color: rgba(226, 225, 225, 1);
  }
}
</style>
