<template>
  <div class="spiderMarket-card" @click="goDetail">
    <div class="card-header">
      <img :src="cardData.coverImg" />
      <!-- <img src="../../../assets/imgs/case04.png" alt="" /> -->
    </div>
    <div class="card-content">
      <div class="title-uploader">
        <span class="title">{{ cardData.name }}</span>
        <span class="uploader">上传者: {{ cardData.createUserName }}</span>
      </div>
      <div class="comment-score">
        <span class="comment">{{ cardData.commentNum }}个评论</span>
        <span class="score">
          {{ cardData.score !== 0 ? `${cardData.score}分` : `未评分` }}
        </span>
      </div>
      <div class="description">
        <span>描述: {{ cardData.introduction }}</span>
      </div>
      <div class="price-button">
        <span class="price">￥{{ cardData.price }}</span>
        <el-button type="primary" class="button" @click.stop="onCreateOrder">
          立即购买
        </el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ElMessage, ElMessageBox } from "element-plus";
import { defineProps, ref, computed, defineEmits } from "vue";
import VueCookies from "vue-cookies";
import { ROLE } from "@/constant/role.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
const props = defineProps({
  cardData: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["goDetail"]);
const role = ref(VueCookies.get("userInfo").spiderPermission?.role);
// role.value = 3;
const privilegeNum = computed(() => {
  if (role.value === ROLE.TRIAL) return 1;
  else if (role.value === ROLE.STANDARD) return 10;
  else return 20;
});
const onCreateOrder = () => {
  ElMessageBox.confirm(
    `您当前拥有爬虫模板使用特权数量${privilegeNum.value}个, 是否使用？`,
    "提示",
    {
      type: "warning",
      confirmButtonText: "立即使用",
      cancelButtonText: "直接支付",
      customClass: "order-class",
    }
  )
    .then(() => {
      confirmUse();
    })
    .catch(() => {
      confirmPayment();
    });
};
const goDetail = () => {
  emits("goDetail", props.cardData);
};
const confirmUse = () => {
  console.log("使用成功");
};
const confirmPayment = () => {
  console.log("支付成功");
};
</script>

<style lang="scss" scoped>
.spiderMarket-card {
  width: 276px;
  height: 300px;
  cursor: pointer;
  padding: 16px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  transition: box-shadow 0.3s, transform 0.3s;
  .card-header {
    height: 116px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card-content {
    .title-uploader {
      padding: 10px 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        color: #101010;
        font-size: 16px;
        font-weight: 700;
      }
      .uploader {
        color: #9a9a9a;
        font-size: 14px;
      }
    }
    .comment-score {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 5px;
      color: #9a9a9a;
    }
    .description {
      font-size: 14px;
      color: #9a9a9a;
      padding: 10px 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .price-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 5px;
    .price {
      color: #e82727;
      font-size: 20px;
    }
    .button {
      border-radius: 20px;
    }
  }
}
</style>
<style lang="scss">
.order-class {
  position: relative;
  width: 400px;
  height: 150px;
  .el-message-box__btns {
    justify-content: center;
  }
}
</style>
