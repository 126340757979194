<template>
  <div class="list-table">
    <div class="table-wrapper">
      <el-table
        :data="spiderDataList"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="name" label="爬虫名称" min-width="300px">
          <template #default="{ row }">
            <div class="colunm-name">
              <i class="iconfont icon-DA"></i>
              <div class="colunm-title" v-showTip>
                <el-tooltip placement="bottom-start" effect="light">
                  <template #content>
                    {{ row.name }}
                  </template>
                  <div class="colunm-text">
                    <span class="ellipse-text">
                      {{ row.name }}
                    </span>
                  </div>
                </el-tooltip>
              </div>
              <!-- <el-tag
                size="small"
                color="#3894FF26"
                class="publish"
                v-if="row.isMarket == 1"
              >
                已发布
              </el-tag>
              <el-tag size="small" type="success" v-if="row.isMarket == 2">
                已购买
              </el-tag> -->
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="spiderType" label="爬虫类型">
          <template #default="scope">
            {{
              scope.row.spiderType
                ? SPIDER_TYPE_MAP[scope.row.spiderType].label
                : "--"
            }}
          </template>
        </el-table-column> -->
        <el-table-column prop="spiderStatus" label="爬虫状态">
          <template #default="scope">
            <SpiderStatusTag :status="scope.row.spiderStatus"></SpiderStatusTag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180">
          <template #default="scope">
            {{ timeFormatter(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" width="180">
          <template #default="scope">
            {{ timeFormatter(scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="180">
          <template #default="{ row }">
            <div class="spider-item-option">
              <el-button link @click="gotoDetail(row)" type="primary">
                详情
              </el-button>
              <el-button
                link
                v-if="row.spiderStatus !== SPIDER_STATUS.ENABLED"
                @click="handleClickOperate(row, 'enable')"
                type="primary"
              >
                启用
              </el-button>
              <el-button
                link
                v-if="row.spiderStatus === SPIDER_STATUS.ENABLED"
                @click="handleClickOperate(row, 'disable')"
                type="primary"
              >
                禁用
              </el-button>
              <el-button link type="primary">
                <el-dropdown trigger="click">
                  <el-button link type="primary">
                    更多
                    <el-icon class="el-icon--right"><arrow-down /></el-icon>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <!-- row.spiderStatus !== SPIDER_STATUS.NOTTESTED && -->
                      <!--   -->
                      <!-- <el-dropdown-item
                      @click="handleClickPublish(row)"
                      v-if="ifShowPublish(row)"
                    >
                      发布
                    </el-dropdown-item> -->
                      <el-dropdown-item
                        v-if="row.spiderStatus === SPIDER_STATUS.DRAFT"
                        @click="handleClickOperate(row, 'disable')"
                      >
                        禁用
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="row.spiderStatus !== SPIDER_STATUS.DRAFT"
                        @click="handleClickOperate(row, 'backToDraft')"
                      >
                        退回到草稿
                      </el-dropdown-item>
                      <el-dropdown-item @click="handleClickDelete(row)">
                        删除
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-button>
              <!-- </span> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <SpiderTemplateDrawerPublish
      @refresh="updateSpider"
      ref="spiderTemplateDrawerPublishRef"
    ></SpiderTemplateDrawerPublish>
  </div>
</template>

<script setup>
import {
  ref,
  defineExpose,
  defineProps,
  defineEmits,
  onMounted,
  onBeforeUnmount,
} from "vue";
import VueCookies from "vue-cookies";
import { useRouter } from "vue-router";

import * as ws from "@/utils/websocket";
import { httpPost, spiderDesignHost } from "@/api/httpService.js";

import { usePagination } from "@/utils/usePagination";
import { timeFormatter } from "@/utils/time.js";
import {
  SPIDER_STATUS,
  getStatusAction,
  SPIDER_STATUS_ACTION,
} from "@/constant/spider_status.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { remindErrorMessage } from "@/utils/utils";
import SpiderStatusTag from "@/components/base/SpiderStatusTag.vue";
import SpiderTemplateDrawerPublish from "./SpiderTemplateDrawerPublish.vue";
import { ElMessage } from "element-plus";
import { SPIDER_TYPE_MAP } from "@/constant/spider_type.js";

const router = useRouter();

const emits = defineEmits(["refresh", "select-data"]);
const props = defineProps({
  spiderDataList: {
    type: Array,
    default: () => {},
  },
});
const selectedItemList = ref([]);
const handleSelectionChange = (val) => {
  selectedItemList.value = val;
  emits("select-data", selectedItemList.value);
};

//是否显示更多里面的发布
const ifShowPublish = (row) => {
  if (row.isMarket == 0) {
    if (row.spiderStatus != SPIDER_STATUS.NOTTESTED) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

//前往详情页
const gotoDetail = (row) => {
  window.open(
    spiderDesignHost +
      `/spider/design/index.html?id=${row.spiderUid}&status=detail`
  );
};

//更多的下拉
//发布
const spiderTemplateDrawerPublishRef = ref({});
const handleClickPublish = (row) => {
  spiderTemplateDrawerPublishRef.value.openDrawer(row);
};
//更新spider
const updateSpider = () => {
  emits("refresh");
};
//删除爬虫
const handleClickDelete = (row) => {
  // if (row.isMarket == 1) {
  //   ElMessage.warning("当前爬虫模板未下架，请先将当前爬虫下架");
  // }
  confirmDeleteBox("此操作将永久删除爬虫模板" + row.name + ", 是否继续?").then(
    async () => {
      let res = await httpPost("/spider/v1/deleteSpiders", {
        spiderUidList: [row.spiderUid],
      });
      if (res.code == 0) {
        ElMessage.success("删除成功");
        emits("refresh");
      } else {
        remindErrorMessage.warning(res.message);
      }
    }
  );
};
//改变状态
const handleClickOperate = (row, operate) => {
  switch (operate) {
    case "enable":
      changeStatus(row, SPIDER_STATUS.ENABLED);
      break;
    case "disable":
      confirmDeleteBox("禁用后，该爬虫将无法用于创建项目，确认禁用吗?")
        .then(async () => {
          await changeStatus(row, SPIDER_STATUS.DISABLED);
        })
        .catch(() => {});
      break;
    case "backToDraft":
      changeStatus(row, SPIDER_STATUS.DRAFT);
      break;
    default:
      break;
  }
};
const changeStatus = async (row, spiderStatus) => {
  let params = {
    spiderUid: row.spiderUid,
    spiderStatus,
  };
  let res = await httpPost("/spider/v1/changeSpidersStatus", params);
  if (res.code == 0) {
    ElMessage.success("更新成功");
    emits("refresh");
  }
};

//监听爬虫设计器的postMessage
function handleReceiveMessage(event) {
  if (
    spiderDesignHost.indexOf(event.origin) != -1 &&
    event.data.name === "spider-design-refresh"
  ) {
    // console.log(event.data);
    // console.log(event.origin);
    // console.log(event.source);
    updateSpider();
  }
}

onMounted(() => {
  //监听爬虫设计器的postMessage
  window.addEventListener("message", handleReceiveMessage);
});
onBeforeUnmount(() => {
  window.removeEventListener("message", handleReceiveMessage);
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
.list-table {
  padding: 20px;
  padding-bottom: 0;
}
.table-wrapper {
  overflow: auto;
  .publish {
    color: #1a78ff;
    border-color: #3894ff26;
  }
  .icon-DA {
    color: #7f44f0;
    margin-right: 10px;
  }

  .edit {
    color: #7f44f0;
  }
  .option {
    cursor: pointer;
    margin-right: 10px;
  }
  .spider-item-option {
    display: flex;
    align-items: center;
  }
  .colunm-name {
    width: 100%;
    display: flex;
    align-items: center;
    .colunm-title {
      max-width: 70%;
    }
    .colunm-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ellipse-text {
      margin-right: 10px;
    }
  }
}
:deep(.el-icon--right) {
  margin-left: 0;
}
</style>
