<template>
  <div class="search-market">
    <div class="input-box">
      <el-input
        class="option input"
        clearable
        placeholder="请输入爬虫名称搜索"
        v-model="searchForm.spiderName"
      ></el-input>
      <el-select
        v-model="searchForm.spiderStatus"
        class="option select"
        clearable
      >
        <el-option
          v-for="item in SPIDER_MARKET_STATUS"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-button
        type="primary"
        class="option"
        @click="handleClickSearchMarketShop"
      >
        查询
      </el-button>
    </div>
  </div>
  <div>
    <div>
      <SpiderMarketList
        :data="spiderMarketDataList"
        :loading="ifLoading"
        :ifSearchEmpty="ifSearchEmpty"
      />
    </div>
    <!-- <div
      class="empty-container"
      v-if="(!ifLoading && spiderMarketDataList.length == 0) || ifSearchEmpty"
    >
      <EmptyStatus :size="350" :imageSrc="require('@/assets/imgs/empty2.png')">
        <template #description>
          <div style="color: #6c6c6c" v-if="ifSearchEmpty">暂无搜索结果</div>
          <div style="color: #6c6c6c" v-if="!ifSearchEmpty">暂无爬虫数据</div>
        </template>
      </EmptyStatus>
    </div> -->
  </div>
  <div class="pagination-box">
    <el-pagination
      v-model:current-page="pageNum"
      v-model:page-size="pageSize"
      small
      background
      layout="total, prev, pager, next, sizes"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="goToPage"
    ></el-pagination>
  </div>
</template>

<script setup>
import { onMounted, ref, defineExpose, computed } from "vue";
import SpiderMarketList from "./SpiderMarketList.vue";
import { usePagination } from "@/utils/usePagination";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
import { httpPost } from "@/api/httpService.js";
const ifLoading = ref(false);
const spiderMarketDataList = ref([]);
const SPIDER_MARKET_STATUS = [
  {
    label: "全部",
    value: null,
  },
  {
    label: "系统提供",
    value: 1,
  },
  {
    label: "用户提供",
    value: 0,
  },
];
const searchForm = ref({
  spiderName: "",
  spiderStatus: null,
});

const ifSearchEmpty = ref(false);

const checkSearchForm = computed(() => {
  if (searchForm.value.spiderName !== "") {
    return true;
  } else {
    return false;
  }
});

const handleClickSearchMarketShop = () => {
  getSpiderShopList();
};
//TODO 获取爬虫商品待对接
const getSpiderShopList = async () => {
  ifLoading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    name: searchForm.value.spiderName,
    isSystem: searchForm.value.spiderStatus,
  };
  let res = await httpPost("/spiderMarket/v1/searchSpiderMarketList", params);
  if (res.code == 0) {
    if (checkSearchForm.value && res.data.data.length === 0) {
      ifSearchEmpty.value = true;
    } else {
      ifSearchEmpty.value = false;
    }
    spiderMarketDataList.value = res.data.data;
    total.value = res.data.total;
    ifLoading.value = false;
  }
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getSpiderShopList,
});
defineExpose({
  getSpiderShopList,
});
</script>

<style lang="scss" scoped>
.search-market {
  position: absolute;
  top: 0;
  left: 210px;
}
.input-box {
  display: flex;
  .option {
    margin-right: 24px;
  }
  .input {
    width: 240px;
  }
  .select {
    width: 160px;
  }
}
.pagination-box {
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
}
.empty-container {
  height: $project-page-min-height;
}
</style>
