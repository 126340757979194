<template>
  <el-dialog
    v-model="DLGVisible"
    :before-close="closeDLG"
    :title="DLGTitle"
    class="base-dialog"
    :close-on-click-modal="false"
  >
    <el-form
      ref="formRef"
      :model="form"
      :rules="formRules"
      style="width: 90%"
      label-position="right"
      label-width="100px"
      hide-required-asterisk
    >
      <el-form-item prop="groupIdList" label="项目标签：">
        <el-select
          v-model="form.groupIdList"
          multiple
          placeholder="请选择项目标签"
        >
          <el-option
            v-for="item in tagOptions"
            :key="item.uid"
            :label="item.name"
            :value="item.uid"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="已选项目：">
        <div class="project-list">
          <TagSelectorBox
            ref="tagSelectorBoxRef"
            :data="projectNameList"
            icon="icon-wodexiangmu"
            :icon-style="{ color: '#7F44F0', fontSize: '14px' }"
            min-height="176px"
            :closable="true"
            :selectable="false"
            :editable="false"
            @delete-tag="handleClickRemoveProject"
          />
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          @click="handleClickConfirm"
          :disabled="saveButtonDisable"
        >
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  nextTick,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import { Debounce } from "@/utils/utils";
import { ElMessage } from "element-plus";
import TagSelectorBox from "@/components/tagSelector/TagSelectorBox.vue";
import { remindErrorMessage } from "@/utils/utils";

const emits = defineEmits(["submit"]);
const route = useRoute();

// #region 表格数据相关
const form = ref({
  groupIdList: [],
});
const formRules = ref({
  groupIdList: [
    { required: true, message: "请选择项目标签", trigger: "change" },
  ],
});
const formRef = ref(null);
const projectList = ref([]);
const projectNameList = ref([]);
const projectIdList = ref([]);
// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
  form.value = {
    groupIdList: [],
  };
};
// #endregion 表格数据相关

// #region 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("批量添加标签");
const openDLG = (params) => {
  getTagList();
  projectList.value = params;
  projectNameList.value = params.map((item) => item.name);
  projectIdList.value = params.map((item) => item.projectUid);
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  nextTick(() => {
    resetForm();
  });
};
// #endregion 弹窗相关

// #region 获取列表相关
//获取标签列表
const tagOptions = ref([]);
const getTagList = async () => {
  // let res = await httpPost("/project/v1/get/groups", {});
  let res = await httpPost("/projectTag/v1/searchProjectTagAndNum");
  if (res.code == 0) {
    tagOptions.value = res.data;
  }
};
// #endregion 获取列表相关

// 移除某一项目
const handleClickRemoveProject = (value, index) => {
  const obj = projectList.value.find((item) => item.projectName === value);
  projectNameList.value = projectNameList.value.filter(
    (item) => item !== value
  );
  projectIdList.value = projectIdList.value.filter((item) => item !== obj.id);
};
// 点击确认
const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
const handleClickConfirm = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      showSaveButtonDisable();
      batchAddTag();
    }
  });
};
// 添加标签
const batchAddTag = Debounce(async () => {
  let params = {
    tagUidList: form.value.groupIdList,
    projectUidList: projectIdList.value,
  };
  let res = await httpPost("/project/v1/addBatchProjectToTag", params);
  if (res.code == 0) {
    ElMessage.success("添加成功");
    emits("submit");
    closeDLG();
  } else {
    remindErrorMessage(res.message);
  }
  hideSaveButtonDisable();
});

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/base-dialog.scss";
:deep(.el-input__inner) {
  width: 240px;
}
.project-list {
  width: 100%;
}
</style>
