<template>
  <div class="back">
    <el-button @click="goBack">返回</el-button>
  </div>
  <div class="spider-detail">
    <div class="spider-box">
      <div class="spider-image">
        <img :src="spiderDetailInfo.coverImg" />
      </div>
      <div class="spider-info">
        <div class="spider-name">
          <span class="name">爬虫名称：</span>
          <span class="content">{{ spiderDetailInfo.name }}</span>
        </div>
        <div class="spider-description">
          <span class="name">爬虫描述：</span>
          <span class="content">{{ spiderDetailInfo.introduction }}</span>
        </div>
        <div class="spider-uploader">
          <span class="name">上传者：</span>
          <span class="content">{{ spiderDetailInfo.createUserName }}</span>
        </div>
        <div class="spider-score">
          <span class="score">
            <span class="name">爬虫评分：</span>
            <span class="content">{{ spiderDetailInfo.score }}分</span>
          </span>
        </div>
        <div class="spider-price">
          <span class="price">
            <span class="name">爬虫价格：</span>
            <span class="content">￥ {{ spiderDetailInfo.price }}</span>
          </span>
          <span>
            <el-button type="primary">立即购买</el-button>
          </span>
        </div>
        <div class="pruchase-count">
          <span class="name">购买次数：</span>
          <span class="content">{{ spiderDetailInfo.sales }}次</span>
        </div>
      </div>
    </div>
    <div class="data-struct">
      <span class="title">数据结构</span>
      <div class="table">
        <el-table :data="currentDataStructList" style="width: 100%">
          <el-table-column type="selection" />
          <el-table-column prop="name" label="字段名称" width="200" />
          <el-table-column prop="label" label="字段说明" width="200" />
          <el-table-column prop="type" label="字段类型" width="150">
            <template #default="scope">
              <span>{{ fieldTypeFormatter(scope.row.type) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="size" label="字段长度" width="150" />
          <el-table-column prop="notNull" label="是否必须" width="150">
            <template #default="scope">
              <span>{{ scope.row.notNull === 1 ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="defaultValue" label="默认值">
            <template #default="scope">
              <span>
                {{
                  scope.row.defaultValue !== "" ? scope.row.defaultValue : "--"
                }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          small
          background
          layout="total, prev, pager, next, sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        ></el-pagination>
      </div>
    </div>
    <div class="score-comment" v-if="ifPurchased">
      <div class="score">
        <span class="title">总体评分</span>
        <el-rate
          v-model="starNum"
          show-score
          :colors="['#f85926', '#f85926', '#f85926']"
          text-color="#ff9900"
          score-template="{value} 分"
        ></el-rate>
      </div>
      <div class="publish-comment">
        <div class="title">发布评论</div>
        <div class="publish">
          <el-input v-model="comment" :rows="4" type="textarea" class="input" />
          <el-button type="primary" @click="onPublishComment">发布</el-button>
        </div>
      </div>
    </div>
    <SpiderCommentList ref="spiderCommentListRef" />
  </div>
</template>

<script setup>
import { onMounted, ref, watch, nextTick, defineEmits, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
// import { usePagination } from "@/utils/usePagination";
import { httpPost } from "@/api/httpService.js";
import { ElMessage } from "element-plus";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import SpiderCommentList from "./SpiderCommentList.vue";
import { FIELD_TYPE_MAP } from "@/constant/field_type.js";
const route = useRoute();
const spiderInfoUid = ref(null);
const ifLoading = ref(false);
const ifPurchased = ref(true);
//爬虫市场详情info
const spiderDetailInfo = ref({});
const comment = ref("");
const router = useRouter();
const spiderCommentListRef = ref(null);
const starNum = ref(0);
const pageNum = ref(1);
const pageSize = ref(10);
watch(
  () => route.params,
  (newVal) => {
    spiderInfoUid.value = newVal.spiderUid;
  },
  {
    immediate: true,
    deep: true,
  }
);
//返回上一级界面
const goBack = () => {
  router.push({
    name: "SpiderTemplateIndex",
  });
};
const fieldTypeFormatter = (type) => {
  return FIELD_TYPE_MAP[type]?.label;
};
fieldTypeFormatter("string");
//TODO 获取爬虫详情信息
const getSpiderDetailInfo = async () => {
  ifLoading.value = true;
  let params = {
    uid: spiderInfoUid.value,
  };
  let res = await httpPost("/spiderMarket/v1/searchSpiderInfoByUid", params);
  if (res.code == 0) {
    spiderDetailInfo.value = res.data;
    ifLoading.value = false;
  }
};
//TODO 用户发布评论-待对接
const onPublishComment = async () => {
  if (starNum.value === 0) {
    ElMessage.warning("请对爬虫评分后再发布评论");
    return;
  }
  let params = {
    marketSpiderUid: spiderInfoUid.value,
    isMain: 1,
    score: starNum.value,
    content: comment.value,
  };
  let res = await httpPost("/spiderMarket/v1/addComment", params);
  if (res.code === 0) {
    ElMessage.success("发布成功");
    spiderCommentListRef.value?.getSpiderCommentList();
    comment.value = "";
    starNum.value = 0;
  }
};
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);

const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "爬虫模板",
      name: "SpiderTemplateIndex",
      params: {},
    },
    {
      title: "爬虫市场",
      name: "SpiderTemplateIndex",
      params: {},
    },
    {
      title: "爬虫详情",
      name: "SpiderTemplateMarketDetail",
      params: {},
    },
  ];
  const pageTitle = "爬虫详情";
  emits("setBreadcrumbList", breadcrumbList);
  emits("setPageTitle", pageTitle);
};
//前端分页
const currentDataStructList = computed(() => {
  const startIndex = (pageNum.value - 1) * pageSize.value;
  const endIndex = startIndex + pageSize.value;
  return spiderDetailInfo.value.spidersDataStructConfigDTOList?.slice(
    startIndex,
    endIndex
  );
});
const handleSizeChange = (size) => {
  pageSize.value = size;
};
const goToPage = (page) => {
  pageNum.value = page;
};
const total = computed(() => {
  return spiderDetailInfo.value.spidersDataStructConfigDTOList?.length;
});
// const { pageNum, pageSize, goToPage, handleSizeChange } = usePagination({
//   fetchDataFn: getSpiderDetailInfo,
// });
onMounted(() => {
  setBreadcrumb();
  getSpiderDetailInfo();
});
</script>

<style lang="scss" scoped>
.back {
  position: absolute;
  top: -76px;
  right: 0px;
}
.spider-detail {
  .spider-box {
    display: flex;
    .spider-image {
      width: 686px;
      height: 370px;
      padding: 0 30px 0px 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .spider-info {
      flex: 1;
      padding-left: 30px;
      .name {
        color: #6c6c6c;
        font-size: 16px;
        padding: 0 20px 0 0;
      }
      .spider-name {
        margin-bottom: 50px;
        .content {
          font-size: 14px;
          color: #6c6c6c;
        }
      }
      .spider-description {
        padding-bottom: 50px;
        .content {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #6c6c6c;
          font-size: 14px;
        }
      }
      .spider-uploader {
        .name {
          margin-left: 10px;
        }
        margin-bottom: 50px;
        .content {
          color: #6c6c6c;
          font-size: 14px;
        }
      }
      .spider-score {
        margin-bottom: 50px;
        .content {
          color: #f48f42;
          font-size: 14px;
        }
      }
      .spider-price {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 50px;
        .content {
          color: #e82727;
          font-size: 20px;
        }
      }
      .pruchase-count {
        padding-bottom: 50px;
        .content {
          color: #6c6c6c;
          font-size: 14px;
        }
      }
    }
  }
  .data-struct {
    color: #6c6c6c;
    font-size: 14px;
    padding: 20px 0;
    .table {
      width: 100%;
      padding: 20px 5px;
    }
    .pagination-box {
      display: flex;
      margin-top: 24px;
      justify-content: flex-end;
    }
  }
  .score-comment {
    .score {
      .title {
        color: #6c6c6c;
        font-size: 14px;
      }
      display: flex;
      align-items: center;
      gap: 40px;
    }
    .publish-comment {
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      gap: 40px;
      flex-wrap: nowrap;
      .title {
        white-space: nowrap;
        color: #6c6c6c;
        font-size: 14px;
      }
      .publish {
        width: 100%;
        .input {
          padding-bottom: 10px;
        }
      }
    }
  }
  /* .comment-container {
    padding-top: 20px;
    .comment-count {
      padding-bottom: 20px;
      .title {
        color: #6c6c6c;
        font-size: 14px;
      }
    }
    .comment-content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .comment-left {
        display: flex;
        align-items: first baseline;
        gap: 40px;
        padding-left: 20px;
        .avatar {
          .username {
            color: #9a9a9a;
            font-size: 14px;
            padding-left: 3px;
          }
        }
        .comment-info {
          display: flex;
          flex-direction: column;
          gap: 15px;
          .time {
            color: #9a9a9a;
          }
        }
      }
      .comment-right {
        padding-right: 20px;
        .icon-like-reply {
          position: relative;
          top: 65px;
          right: 30px;
          .dianzan-icon {
            padding-right: 20px;
          }
        }
      }
    }
    .reply-info {
      margin-left: 130px;
      margin-top: 20px;
      padding: 10px 10px 10px 10px;
      background-color: rgba(239, 239, 239, 0.7);
      .reply-content {
        display: flex;
        align-items: center;
        gap: 15px;
        padding-bottom: 10px;
        .username {
          color: #9a9a9a;
          font-size: 14px;
        }
        .content {
          color: #6c6c6c;
          font-size: 14px;
        }
      }
      .reply-bottom {
        display: flex;
        align-items: center;
        gap: 25px;
        cursor: pointer;
        padding-bottom: 10px;
        color: #9a9a9a;
      }
      .total-reply {
        color: #9a9a9a;
        font-size: 14px;
      }
    }
    .reply-input {
      margin-left: 130px;
      margin-top: 20px;
      display: flex;
      align-items: flex-start;
      gap: 20px;
      .replyer-info {
        display: flex;
        align-items: center;
        gap: 5px;
        .title {
          color: #9a9a9a;
          font-size: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .input {
        width: 100%;
      }
      .input:hover {
      }
    }
  } */
}
</style>
