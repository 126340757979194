<template>
  <div class="card-container" v-loading="loading">
    <el-row :gutter="20">
      <el-col
        :xs="12"
        :sm="12"
        :md="6"
        :lg="6"
        :xl="6"
        v-for="item in data"
        :key="item"
      >
        <div class="card-box">
          <SpiderMarketCard
            :cardData="item"
            v-bind="$attrs"
            @goDetail="goDetail"
          />
        </div>
      </el-col>
    </el-row>
  </div>
  <div
    class="empty-container"
    v-if="(!loading && data.length == 0) || ifSearchEmpty"
  >
    <EmptyStatus :size="350" :imageSrc="require('@/assets/imgs/empty2.png')">
      <template #description>
        <div style="color: #6c6c6c" v-if="ifSearchEmpty">暂无搜索结果</div>
        <div style="color: #6c6c6c" v-if="!ifSearchEmpty">暂无爬虫数据</div>
      </template>
    </EmptyStatus>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { useRouter } from "vue-router";
import SpiderMarketCard from "./SpiderMarketCard.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  ifSearchEmpty: Boolean,
});
const router = useRouter();

const goDetail = (cardData) => {
  router.push({
    name: "SpiderTemplateMarketDetail",
    params: { spiderUid: cardData.uid },
  });
};
</script>

<style lang="scss" scoped>
/* .card-container {
  margin-top: 20px;
} */
.card-box {
  margin-bottom: 20px;
}
</style>
