<template>
  <div class="spider-template-index">
    <SpiderTemplateTable></SpiderTemplateTable>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, onMounted } from "vue";
import SpiderTemplateTable from "./components/SpiderTemplateTable.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);

const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "爬虫模板",
      name: "SpiderTemplateIndex",
      params: {},
    },
  ];
  const pageTitle = "爬虫模板";
  emits("setBreadcrumbList", breadcrumbList);
  emits("setPageTitle", pageTitle);
};

onMounted(async () => {
  await setBreadcrumb();
});
</script>

<style lang="scss" scoped></style>
