<template>
  <div
    class="schedule-center-index"
    :class="{ 'empty-schedule-index': scheduleList.length == 0 }"
    v-if="!ifShowNoPermission"
  >
    <div class="page-option-bar">
      <span>
        <el-dropdown @command="handleBatchCommand">
          <el-button>
            批量操作
            <el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="batchDelete">
                批量删除
              </el-dropdown-item>
              <el-dropdown-item command="batchEnable">
                批量生效
              </el-dropdown-item>
              <el-dropdown-item command="batchPause">批量暂停</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </span>
      <span>
        <el-button type="primary" @click="handleClickAddSchedule">
          新建策略
        </el-button>
      </span>
    </div>
    <div class="schedule-container">
      <div class="option-bar">
        <div class="search-bar">
          <el-input
            v-model="keywords"
            placeholder="请输入关键词"
            :prefix-icon="Search"
            style="width: 240px; margin-right: 24px"
            clearable
          />
          <el-select
            v-model="scheduleStatus"
            clearable
            placeholder="请选择策略状态"
            style="margin-right: 24px"
          >
            <el-option
              v-for="status in SCHEDULE_STATUS"
              :value="status"
              :label="SCHEDULE_STATUS_MAP[status].label"
              :key="status"
            ></el-option>
          </el-select>
          <el-button @click="handleClickSearch" type="primary">查询</el-button>
        </div>
        <buttonGroup v-model="viewType" @change="handleChangeViewType">
          <el-radio-button :label="VIEW_TYPE.CARD">
            <i class="iconfont icon-qiapian"></i>
            卡片视图
          </el-radio-button>
          <el-radio-button :label="VIEW_TYPE.LIST">
            <i class="iconfont icon-liebiao"></i>
            列表视图
          </el-radio-button>
        </buttonGroup>
      </div>
      <component
        ref="scheduleListComponentRef"
        :is="viewComponent"
        :data="scheduleList"
        :loading="ifShowLoading"
        @delete="onDeleteSchedule"
        @edit="onEditSchedule"
        @enable="onEnableSchedule"
        @pause="onPauseSchedule"
        @view="onViewSchedule"
        @add="handleClickAddSchedule"
      ></component>
      <div class="pagination-box" v-show="scheduleList.length > 0">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          small
          background
          layout="total, prev, pager, next, sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        ></el-pagination>
      </div>
    </div>
  </div>
  <div
    v-if="!ifShowLoading && ifShowNoPermission"
    class="no-permission-container"
  >
    <EmptyStatus :imageSrc="require('@/assets/imgs/empty2.png')" size="300">
      <template #description>
        <div style="font-size: 18px; margin-bottom: 12px">
          {{ ROLE_MAP[role]?.label }}暂无策略的使用权限，您可
          <span class="description-button" @click="goPricing">升级版本</span>
          ，体验采集任务的策略个性化配置。
        </div>
        <div style="font-size: 16px">
          策略用来设置采集项目的执行时间，比如可以设置项目定时采集、循环采集等。
        </div>
      </template>
    </EmptyStatus>
  </div>
  <ScheduleDrawerManage
    ref="scheduleDrawerManageRef"
    @refresh="getScheduleList"
  ></ScheduleDrawerManage>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  onMounted,
  computed,
} from "vue";
import { usePagination } from "@/utils/usePagination";

import { httpPost } from "@/api/httpService.js";
import ScheduleTable from "./components/ScheduleTable.vue";
import ScheduleCardList from "./components/ScheduleCardList.vue";
import { confirmDeleteBox } from "@/utils/messageBox.js";

import VueCookies from "vue-cookies";
import { ROLE, ROLE_MAP } from "@/constant/role.js";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
import { useRouter } from "vue-router";
import buttonGroup from "@/components/base/ButtonGroup.vue";
import ScheduleDrawerManage from "./components/ScheduleDrawerManage.vue";
import { Search } from "@element-plus/icons-vue";
import {
  SCHEDULE_STATUS,
  SCHEDULE_STATUS_MAP,
} from "../../constant/schedule_status";
import { ElMessage } from "element-plus";

const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);

const router = useRouter();

const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "策略中心",
      name: "ScheduleCenterIndex",
      params: {},
    },
  ];
  const pageTitle = "策略中心";
  emits("setBreadcrumbList", breadcrumbList);
  emits("setPageTitle", pageTitle);
};

const role = ref(VueCookies.get("userInfo")?.spiderPermission?.role);

// TODO 测试用权限
// role.value = 3;
const ifShowNoPermission = computed(() => {
  return role.value != ROLE.PREMIUM && role.value != ROLE.CUSTOMIZED;
});

const goPricing = () => {
  router.push({ name: "Pricing" });
};

//前往策略模板管理
const goManageTemplate = (row) => {
  router.push({
    name: "ProjectData",
    params: {
      projectId: row.id,
    },
  });
};

const scheduleListComponentRef = ref(null);
const VIEW_TYPE = {
  CARD: 1,
  LIST: 2,
};
const viewType = ref(VIEW_TYPE.CARD);

const viewComponent = computed(() => {
  let result;
  switch (viewType.value) {
    case VIEW_TYPE.CARD:
      result = ScheduleCardList;
      break;
    case VIEW_TYPE.LIST:
      result = ScheduleTable;
      break;
  }
  return result;
});

const handleChangeViewType = (value) => {
  // 确保视图组件重新触发刷新，正常显示空状态
  ifShowLoading.value = true;
  setTimeout(() => {
    ifShowLoading.value = false;
  }, 0);

  console.log(value);
};

const keywords = ref("");
const scheduleStatus = ref("");
const ifShowLoading = ref(true);
const scheduleList = ref([]);
//获取策略列表
const getScheduleList = async () => {
  if (role.value === ROLE.TRIAL || role.value === ROLE.STANDARD) return;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    status: scheduleStatus.value ?? null,
    name: keywords.value ?? "",
  };
  ifShowLoading.value = true;
  let res = await httpPost("/schedule/v1/pageSchedule", params);
  if (res.code == 0) {
    ifShowLoading.value = false;
    scheduleList.value = res.data.data;
    total.value = res.data.total;
  }

  // let data = [
  //   {
  //     id: 1,
  //     name: "策略1",
  //     executionType: 1,
  //     type: 1,
  //     status: 1,
  //     count: 100,
  //     updateTime: "2023-02-05 19:00:00",
  //   },
  //   {
  //     id: 2,
  //     name: "策略22222222222222222222",
  //     executionType: 2,
  //     type: 2,
  //     status: 2,
  //     updateTime: "2023-02-05 19:00:00",
  //   },
  //   {
  //     id: 3,
  //     name: "策略3",
  //     executionType: 3,
  //     type: 3,
  //     status: 3,
  //     updateTime: "2023-02-05 19:00:00",
  //   },
  //   {
  //     id: 3,
  //     name: "策略3",
  //     executionType: 3,
  //     type: 3,
  //     status: 3,
  //     updateTime: "2023-02-05 19:00:00",
  //   },
  //   {
  //     id: 3,
  //     name: "策略3",
  //     executionType: 3,
  //     type: 3,
  //     status: 3,
  //     updateTime: "2023-02-05 19:00:00",
  //   },
  //   {
  //     id: 3,
  //     name: "策略3",
  //     executionType: 3,
  //     type: 3,
  //     status: 3,
  //     updateTime: "2023-02-05 19:00:00",
  //   },
  // ];
  // data = [];
};
const handleClickSearch = () => {
  pageNum.value = 1;
  getScheduleList();
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getScheduleList,
  ifFetchOnMounted: false,
});
const handleBatchCommand = (command) => {
  if (viewType.value == VIEW_TYPE.CARD) {
    ElMessage.warning("请切换到表格视图再进行批量操作");
    return;
  }
  let selectedScheduleList = scheduleListComponentRef.value.selectedItemList;
  if (!selectedScheduleList?.length) {
    ElMessage.warning("请选择策略后再进行批量操作");
    return;
  }

  switch (command) {
    case "batchDelete":
      onDeleteSchedule(selectedScheduleList);
      break;
    case "batchEnable":
      onEnableSchedule(selectedScheduleList);
      break;
    case "batchPause":
      onPauseSchedule(selectedScheduleList);
      break;
  }
};

//触发删除策略
const onDeleteSchedule = async (scheduleList) => {
  let checkResult = await checkIfScheduleAssociateWithProject(scheduleList);
  console.log("🚀 ~ onDeleteSchedule ~ checkResult:", checkResult);
  confirmDeleteBox(
    checkResult ? "有项目关联策略，确认删除吗？" : "确认删除吗？"
  ).then(async () => {
    let res = await deleteSchedule(scheduleList);
    if (res.code == 0) {
      ElMessage.success("删除成功");
    }
    getScheduleList();
  });
};
//删除策略
const deleteSchedule = async (scheduleList) => {
  const scheduleUidList = getScheduleUidList(scheduleList);
  let params = {
    scheduleUidList,
  };
  let res = httpPost("/schedule/v1/batchRemovalSchedule", params);
  return res;
  // if (res.code == 0) {
  //   ElMessage({
  //     type: "success",
  //     message: res.message,
  //   });
  // }
};
const getScheduleUidList = (scheduleList) => {
  let scheduleUidList;
  if (!Array.isArray(scheduleList)) {
    scheduleUidList = [scheduleList.scheduleUid];
  } else {
    scheduleUidList = scheduleList.map((item) => item.scheduleUid);
  }
  return scheduleUidList;
};
// 检查策略是否关联项目
const checkIfScheduleAssociateWithProject = async (scheduleList) => {
  const scheduleUidList = getScheduleUidList(scheduleList);
  let params = {
    scheduleUidList,
  };
  let res = await httpPost(
    "/schedule/v1/isScheduleAssociatedWithProject",
    params
  );
  if (res.code == 0) {
    return res.data.some((item) => item.isAssociated);
  }
};

const onEditSchedule = (schedule) => {
  scheduleDrawerManageRef.value.openDrawer({ type: "EDIT", schedule });
};
//触发启用策略
const onEnableSchedule = async (scheduleList) => {
  let res = await enableSchedule(scheduleList);
  if (res.code == 0) {
    ElMessage.success(res.data.promptMessage || "操作成功");
  }
  getScheduleList();
};
//启用策略
const enableSchedule = async (scheduleList) => {
  const scheduleUidList = getScheduleUidList(scheduleList);
  let params = {
    scheduleUidList,
  };
  let res = await httpPost("/schedule/v1/takeEffectSchedule", params);
  return res;
  // if (res.code == 0) {
  //   ElMessage({
  //     type: "success",
  //     message: res.message,
  //   });
  // }
};
//暂停策略
const onPauseSchedule = async (scheduleList) => {
  let checkResult = await checkIfScheduleAssociateWithProject(scheduleList);
  confirmDeleteBox(
    checkResult
      ? "有项目关联策略，暂停后，被关联的项目将不会执行新的采集任务，确认暂停吗？"
      : "确认暂停吗？"
  ).then(async () => {
    let res = await pauseSchedule(scheduleList);
    if (res.code == 0) {
      ElMessage.success("暂停成功");
    } else {
    }
    getScheduleList();
  });
};
// 暂停策略
const pauseSchedule = async (scheduleList) => {
  const scheduleUidList = getScheduleUidList(scheduleList);
  let params = {
    scheduleUidList,
  };
  let res = httpPost("/schedule/v1/suspendSchedule", params);
  return res;
  // if (res.code == 0) {
  //   ElMessage({
  //     type: "success",
  //     message: res.message,
  //   });
  // }
};

// 查看策略
const onViewSchedule = (schedule) => {
  scheduleDrawerManageRef.value.openDrawer({ type: "VIEW", schedule });
};

const handleClickAddSchedule = () => {
  scheduleDrawerManageRef.value.openDrawer({ type: "ADD" });
};

const scheduleDrawerManageRef = ref(null);

const getRole = async () => {
  let res = await httpPost("/user/v1/permission", null);
  if (res?.code == 0) {
    let userInfo = VueCookies.get("userInfo") || {};
    userInfo.spiderPermission = res.data;
    userInfo.ifLoginSpider = true;
    VueCookies.set("userInfo", JSON.stringify(userInfo));
    role.value = res.data.role;
  }
};

onMounted(async () => {
  setBreadcrumb();
  if (!role.value) {
    await getRole();
  }
  getScheduleList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/page-option-bar.scss";
.empty-schedule-index {
  // 辅助撑开最小高度
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.schedule-center-index {
  .schedule-container {
    height: 100%;
    .card-container {
      height: calc(100% - 52px);
    }
    .option-bar {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .search-bar {
      }
    }
  }
}
.page-option-bar {
  top: -75px;
  right: 0;
}

.no-permission-container {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pagination-box {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}
</style>
