<template>
  <div class="publish-drawer" v-loading="loading">
    <el-drawer v-model="drawerVisible" size="40%" @close="closeDLG">
      <template #header>
        <span>发布爬虫</span>
      </template>
      <div class="content">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formDataRef"
          label-width="100px"
          @submit.prevent
        >
          <!--input输入-->
          <el-form-item label="爬虫名称:" prop="spiderName">
            <el-input
              clearable
              placeholder="请输入爬虫名称"
              v-model.trim="formData.spiderName"
            ></el-input>
          </el-form-item>
          <el-form-item label="爬虫描述:" prop="desc">
            <el-input
              clearable
              placeholder="请输入爬虫描述"
              type="textarea"
              :rows="5"
              :maxlength="200"
              resize="none"
              show-word-limit
              v-model.trim="formData.desc"
            ></el-input>
          </el-form-item>
          <el-form-item label="爬虫价格:" prop="num">
            <el-input-number
              v-model="formData.num"
              :precision="2"
              :step="0.1"
              controls-position="right"
            />
          </el-form-item>
          <el-form-item label="爬虫图片:" prop="img">
            <div class="upload">
              <!--   :on-success="handleSuccess"
                :before-upload="beforeUpload" -->
              <el-upload
                :limit="1"
                :action="`${baseUrl}/spidermarket/uploadpicture?access_token=`"
                drag
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
                :file-list="fileList"
              >
                >
                <template v-if="formData.img">
                  <img :src="imageUrl" />
                </template>
                <template v-else>
                  <el-icon class="el-icon--upload"><Plus /></el-icon>
                  <div class="el-upload__text">点击上传爬虫图片</div>
                </template>
                <template #tip>
                  <div class="el-upload__tip">
                    只能上传jpg/png文件，建议上传像素比：1280x960
                  </div>
                </template>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item>
            <span class="desc">
              该模板审核通过后，若有用户购买，系统将会收取30%的佣金。
            </span>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div style="flex: auto">
          <el-button @click="handleClickCancel">重置</el-button>
          <el-button
            type="primary"
            @click="handleClickPublish"
            :disabled="ifPublish"
          >
            立即发布
          </el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script setup>
import { ElMessage } from "element-plus";
import axios from "axios";
import { ref, defineExpose, nextTick, defineEmits } from "vue";
import VueCookies from "vue-cookies";

import { baseUrl, httpPost } from "@/api/httpService.js";
import * as ws from "@/utils/websocket";

import { useRouter, useRoute } from "vue-router";
import { Loading } from "element-plus/es/components/loading/src/service";

const router = useRouter();
const route = useRoute();

const loading = ref(false);
const drawerVisible = ref(false);
const formData = ref({
  spiderName: "",
  desc: "",
  num: "",
});
const fileList = ref([]); // 图片数组
const token = ref("");
const role = ref("");
const formDataRef = ref({});
const spiderId = ref(null);
const isEdit = ref(null);
const flagFile = ref(false);
const isJPG = ref("");
const hideUpload = ref(false);
const ifPublish = ref(false); // 是否点击立即发布
const imgData = ref(null); // img上传成功后返回的数据
const imageUrl = ref("");
const testRes = ref([]);
const testFlag = ref(null);

const emits = defineEmits(["refresh"]);
const checkfileList = (rule, value, callback) => {
  if (this.fileList.length == 0) {
    return callback(new Error("上传图片不能为空"));
  } else {
    callback();
  }
};
//校验规则
const rules = ref({
  spiderName: [{ required: true, message: "请输入爬虫名称", trigger: "blur" }], // 字段名
  desc: [{ required: true, message: "请输入爬虫描述", trigger: "blur" }], // 说明
  num: [{ required: true, message: "请输入爬虫价格", trigger: "blur" }], // 说明
  img: [{ validator: checkfileList, trigger: "change" }],
});

// 获取爬虫列表详情进行表单回显
const getSpiderDetail = async () => {
  // TODO 重新对接-暂时搁置
  let res = await httpPost("/spider/get/pub/details/" + spiderId.value);
  if (res.code == 0) {
    formData.value.desc = res.data.introduction;
    formData.value.num = res.data.price;
    imageUrl.value = res.data.img;
    formData.value.img = baseUrl + res.data.img;
    fileList.value.push({
      url: formData.value.img,
    });
    hideUpload.value = true;
  }
};

// // 图片上传失误
// const uploadOnError = () => {
//   ElMessage.warning("图片上传出错！");
// };
// // 图片移除触发
// const handleRemove = (file, fileList) => {
//   hideUpload.value = fileList.value.length < imgData.value;
//   fileList.value = [];
// };
// // 图片回显
// const handlePictureCardPreview = (file) => {
//   if (fileList.value.length <= 1) {
//     imageUrl.value = file.url;
//     console.log("formData.value", formData.value);
//   } else {
//     imageUrl.value = null;
//   }
// };
// 限制图片上传个数
// const handleExceed = (files, fileList) => {
//   ElMessage.warning("当前限制选择 1 个文件");
// };
const beforeUpload = (rawFile) => {
  //文件格式限制
  let types = ["jpg", "jpeg", "png"];
  const isImage = types.some((v) => {
    return rawFile.type.includes(v);
  });
  if (!isImage) {
    ElMessage({
      message: "上传⽂件只支持jpg、jpeg、png格式!",
      type: "warning",
    });
    return false;
  }

  // //文件大小限制
  // let size1M = rawFile.size / 1024 < 500;
  // if (!size1M) {
  //   ElMessage.warning("上传文件大小不能超过 500KB!");
  //   return false;
};
// };
// 图片列表状态变化时触发
// const handleChange = (file, fileList) => {
//   flagFile.value = true;
//   hideUpload.value = fileList.length >= imgData.value;
//   isJPG.value =
//     file.raw.type === "image/jpeg" ||
//     file.raw.type == "image/png" ||
//     file.raw.type == "image/jpg";
//   if (!isJPG.value) {
//     fileList.value = [];
//     ElMessage.warning("上传头像图片只能是 JPG 格式!");
//     hideUpload.value = false;
//   } else {
//     if (fileList.length > 1) {
//       fileList = fileList.slice(-1);
//     }
//     fileList.value = fileList;
//     formData.value.img = file;
//   }
// };
//重置
const handleClickCancel = () => {
  formDataRef.value.resetFields();
  formData.value = {};
};
//发布
const submitForm = () => {
  formDataRef.value.validate((valid) => {
    if (valid) {
      ifPublish.value = true;
      // if (role.value == 0) {
      //   formData.value.ifFree;
      // } else {
      //   formData.value.ifFree=0;
      // }
      let params = {
        ifFree: 0,
        spiderId: spiderId.value,
        price: formData.value.num,
        introduction: formData.value.desc,
        file: formData.value.img.raw,
      };
      let config = {
        headers: {
          Accept: "application/json;charset=UTF-8",
          "Content-Type": "multipart/form-data",
          Authorization: this.$cookies.get("tokenWeb")
            ? "Bearer " + this.$cookies.get("tokenWeb")
            : "",
        },
        withCredentials: false,
      };
      axios
        .post(baseUrl.value + "/market/publish", params, config)
        .then((res) => {
          if (res.data.code == 0) {
            ElMessage.success("发布成功");
            closeDLG();
            emits("refresh");
            // this.$router.push("/spider/mine");
          } else {
            ElMessage.warning(res.message);
          }
        });
    } else {
      ifPublish.value = false;
      // console.log("error submit!!");
      return false;
    }
  });
};
// 开始loading
const startLoading = function () {
  loading.value = Loading.service({
    fullscreen: true,
    background: "rgba(0, 0, 0, 0.1)",
    text: "爬虫检测中...",
  });
};
// 结束loading
const endLoading = function () {
  nextTick(() => {
    // 以服务的方式调用的 Loading 需要异步关闭
    // loading.close();
  });
};
//
const handleClickPublish = () => {
  // 测试爬虫
  formDataRef.value.validate(async (valid) => {
    if (valid) {
      startLoading();
      testRes.value = [];
      ws.openSocket("TEST" + spiderId.value, getMsg());
      // TODO 重新对接-暂时搁置
      let res = await httpPost("/spider/test/" + spiderId.value, null);
    }
  });
};
const getMsg = (res) => {
  if (res == "START") {
    // this.startLoading();
  } else if (res == "FINISH") {
    testFlag.value = testRes.value.length == 0 ? false : true;
    if (testFlag.value == true) {
      const h = this.$createElement;
      this.$notify({
        title: "检测爬虫",
        message: h("i", { style: "color: #67C23A" }, "爬虫检测成功"),
      });
      this.submitForm();
    } else {
      this.endLoading();
    }
  } else if (res == "FAIL") {
    endLoading();
    testFlag.value = false;
    const h = this.$createElement;
    this.$notify({
      title: "检测爬虫",
      message: h("i", { style: "color: #F56C6C" }, "爬虫检测失败"),
    });
  } else {
    testRes.value.push(JSON.parse(res));
  }
};
const openDrawer = (row) => {
  drawerVisible.value = true;
  formData.value = row;
  formData.value.num = 0.0;
  token.value = VueCookies.get("tokenWeb");
  spiderId.value = row.id;
  role.value = VueCookies.get("userInfo").spiderPermission.role;
  // console.log("row", row);
  isEdit.value = route.query.isEdit;
  if (isEdit.value) {
    getSpiderDetail();
  }
};
const closeDLG = () => {
  drawerVisible.value = false;
  nextTick(() => {
    resetForm();
  });
};
// 重置表格
const resetForm = () => {
  formDataRef.value.resetFields();
  formData.value = {};
};
defineExpose({ openDrawer });
</script>

<style lang="scss" scoped>
.publish-drawer {
  :deep(.el-drawer__header) {
    height: 70px;
    padding: 20px;
    border-bottom: 1px solid rgba(220, 220, 220, 1);
    margin-bottom: 0;
    text-align: center;
    color: rgba(16, 16, 16, 1);
    font-size: 18px;
  }
  :deep(.el-drawer__body) {
    padding: 24px 43px 0px 32px;
  }
  .upload {
    width: 100%;
    :deep(.el-upload--picture-card) {
      width: 100%;
    }

    :deep(.el-upload-dragger) {
      padding: 72px 0;
    }
    :deep(.el-upload-dragger .el-upload__text) {
      color: rgba(190, 190, 190, 1);
      font-size: 14px;
      font-family: SourceHanSansSC-regular;
    }
  }
  .desc {
    color: $theme-color;
  }
  :deep(.el-form-item) {
    margin-bottom: 24px;
  }
}
</style>
