<template>
  <div class="card-container">
    <el-row :gutter="30">
      <el-col
        :sm="12"
        :md="12"
        :lg="6"
        :xl="4"
        v-for="item in spiderDataList"
        :key="item"
      >
        <div class="card-box">
          <SpiderCard
            @go="handleClickGoDetail(item)"
            :cardData="item"
            @publish="handleClickPublish(item)"
            @delete="handleClickDelete(item)"
            @operate="(operate) => handleClickOperate(item, operate)"
          ></SpiderCard>
        </div>
      </el-col>
    </el-row>
    <SpiderTemplateDrawerPublish
      ref="spiderTemplateDrawerPublishRef"
      @refresh="updateSpider"
    ></SpiderTemplateDrawerPublish>
  </div>
</template>

<script setup>
import {
  ref,
  defineExpose,
  defineProps,
  defineEmits,
  nextTick,
  onMounted,
  onBeforeUnmount,
} from "vue";
import VueCookies from "vue-cookies";

import { httpPost, spiderDesignHost } from "@/api/httpService.js";
import * as ws from "@/utils/websocket";

import SpiderTemplateDrawerPublish from "./SpiderTemplateDrawerPublish.vue";
import SpiderCard from "./SpiderCard.vue";
import {
  getStatusAction,
  SPIDER_STATUS,
  SPIDER_STATUS_ACTION,
} from "@/constant/spider_status.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { remindErrorMessage } from "@/utils/utils";

import { ElMessage } from "element-plus";
import { ElLoading } from "element-plus";
const emits = defineEmits(["refresh"]);
const props = defineProps({
  spiderDataList: {
    type: Array,
    default: () => {},
  },
});

//前往详情页
const handleClickGoDetail = (item) => {
  window.open(
    spiderDesignHost +
      `/spider/design/index.html?id=${item.spiderUid}&status=detail`
  );
};

//改变状态
const handleClickOperate = (row, operate) => {
  switch (operate) {
    case "enable":
      changeStatus(row, SPIDER_STATUS.ENABLED);
      break;
    case "disable":
      confirmDeleteBox("禁用后，该爬虫将无法用于创建项目，确认禁用吗?")
        .then(async () => {
          await changeStatus(row, SPIDER_STATUS.DISABLED);
        })
        .catch(() => {});
      break;
    case "backToDraft":
      changeStatus(row, SPIDER_STATUS.DRAFT);
      break;
    default:
      break;
  }
};
const changeStatus = async (row, spiderStatus) => {
  let params = {
    spiderUid: row.spiderUid,
    spiderStatus,
  };
  let res = await httpPost("/spider/v1/changeSpidersStatus", params);
  if (res.code == 0) {
    ElMessage.success("更新成功");
    emits("refresh");
  }
};

//删除
const handleClickDelete = (item) => {
  // console.log("删除", item.id);
  confirmDeleteBox("此操作将永久删除爬虫模板" + item.name + ", 是否继续?").then(
    async () => {
      let params = {
        spiderUidList: [item.spiderUid],
      };
      let res = await httpPost("/spider/v1/deleteSpiders", params);
      if (res.code == 0) {
        ElMessage.success("删除成功");
        emits("refresh");
      } else {
        remindErrorMessage.warning(res.message);
      }
    }
  );
};
//发布爬虫抽屉
const spiderTemplateDrawerPublishRef = ref({});
const handleClickPublish = (item) => {
  spiderTemplateDrawerPublishRef.value.openDrawer(item);
};
//更新spider
const updateSpider = () => {
  emits("refresh");
};

//监听爬虫设计器的postMessage
function handleReceiveMessage(event) {
  if (
    spiderDesignHost.indexOf(event.origin) != -1 &&
    event.data.name === "spider-design-refresh"
  ) {
    // console.log(event.data);
    // console.log(event.origin);
    // console.log(event.source);
    updateSpider();
  }
}

onMounted(() => {
  //监听爬虫设计器的postMessage
  window.addEventListener("message", handleReceiveMessage);
});
onBeforeUnmount(() => {
  window.removeEventListener("message", handleReceiveMessage);
});
</script>

<style lang="scss" scoped>
.card-box {
  margin-bottom: 32px;
}
.pagination-box {
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
}
</style>
