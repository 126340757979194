<template>
  <div class="task-record-index">
    <TaskRecordTable></TaskRecordTable>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, onMounted } from "vue";
import { httpPost } from "@/api/httpService.js";
import TaskRecordTable from "./components/TaskRecordTable.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);

const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "任务记录",
      name: "TaskRecordIndex",
      params: {},
    },
  ];
  const pageTitle = "任务记录";
  emits("setBreadcrumbList", breadcrumbList);
  emits("setPageTitle", pageTitle);
};

onMounted(async () => {
  await setBreadcrumb();
});
</script>

<style lang="scss" scoped></style>
