<template>
  <div>
    <div class="comment-count">
      <span class="title">评论({{ formatTotal }})</span>
    </div>
    <div v-if="allComments > 0" v-loading="ifLoading">
      <template v-for="cardData in spiderCommentList" :key="cardData.uid">
        <SpiderCommentCard
          :data="cardData"
          :total="allComments"
          @refresh="getSpiderCommentList"
        />
      </template>
    </div>
    <div class="empty-comment" v-if="allComments === 0">
      <EmptyStatus :imageSrc="require('@/assets/imgs/empty1.png')">
        <template #description>
          <div class="empty-text">暂无评论</div>
        </template>
      </EmptyStatus>
    </div>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="pageNum"
        v-model:page-size="pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="totalComments"
        @size-change="handleSizeChange"
        @current-change="goToPage"
      ></el-pagination>
    </div>
  </div>
</template>

<script setup>
import SpiderCommentCard from "./SpiderCommentCard.vue";
import { usePagination } from "@/utils/usePagination";
import { httpPost } from "@/api/httpService.js";
import { ref, watch, computed, defineExpose } from "vue";
import { useRoute } from "vue-router";
import EmptyStatus from "@/components/base/EmptyStatus.vue";

const route = useRoute();
const spiderCommentList = ref([]);
const marketSpiderUid = ref(0);
const ifLoading = ref(false);

const allComments = ref(0);

const totalComments = ref(0);
//TODO 获取用户评论列表-待对接
const getSpiderCommentList = async () => {
  ifLoading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    marketSpiderUid: marketSpiderUid.value,
  };
  let res = await httpPost("/spiderMarket/v1/searchCommentList", params);
  if (res.code == 0) {
    spiderCommentList.value = res.data.comments.data;
    allComments.value = res.data.totalComments;
    totalComments.value = res.data.comments.total;
    ifLoading.value = false;
  }
};
const formatTotal = computed(() => {
  if (allComments.value > 100) {
    return `${Math.floor(allComments.value / 100) * 100}+`;
  } else {
    return allComments.value;
  }
});
watch(
  () => route.params,
  (newVal) => {
    marketSpiderUid.value = newVal.spiderUid;
  },
  {
    immediate: true,
    deep: true,
  }
);
defineExpose({
  getSpiderCommentList,
});
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getSpiderCommentList,
});
</script>

<style lang="scss" scoped>
.comment-count {
  padding-top: 20px;
  .title {
    color: #6c6c6c;
    font-size: 14px;
  }
}
.pagination-box {
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
}
</style>
